import * as monaco from 'monaco-editor-core';
import 'monaco-json/release/esm/monaco.contribution';

monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
  ...monaco.languages.json.jsonDefaults.diagnosticsOptions,
  allowComments: false,
});

export function toggleValidation(shouldValidate) {
  if (
    shouldValidate !==
    monaco.languages.json.jsonDefaults.diagnosticsOptions.validate
  ) {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      ...monaco.languages.json.jsonDefaults.diagnosticsOptions,
      validate: shouldValidate,
    });
  }
}
