import _ from 'lodash';

const isOpen = (experiment: string | undefined): boolean => {
  if (!experiment) {
    return false;
  }

  return experiment !== 'old' && experiment !== 'false';
};

export const isExperimentOpen = (experimentName: string): boolean => {
  const experiments = _.get(window, 'editorModel.runningExperiments');

  return experiments && isOpen(experiments[experimentName]);
};
