import React, { Component } from 'react';
import style from './Documentation.scss';
import ScrollableWidgetDiv from '../ScrollableWidgetDiv/ScrollableWidgetDiv';
import marked from 'marked';
import { getBiLogger } from '../../services/bi';
import { ideAutocompleteReadMoreClick } from '@wix/bi-logger-platform/v2';

const READ_MORE_TEXT = 'Read more';
const READ_MORE_ID = 'readMoreLink';

const renderer = new marked.Renderer();
renderer.link = (href, title, text) => {
  if (text === READ_MORE_TEXT) {
    return `<a id="${READ_MORE_ID}" target="_blank" href="${href}" title="${
      title || text
    }">${text}</a>`;
  }

  return `<a target="_blank" href="${href}" title="${title}">${text}</a>`;
};

export class Documentation extends Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  getDocumentationHtml() {
    const { doc, url } = this.props;
    const markdownText = doc;
    let documentationHtml = marked(markdownText, {
      renderer,
    });
    if (url) {
      documentationHtml += `<a id="${READ_MORE_ID}" target="_blank" href="${url}" className="${style.readMore}">
          Read more...
        </a>`;
    }

    return documentationHtml;
  }

  handleClick(e) {
    if (e.target.id !== READ_MORE_ID) {
      return;
    }
    e.stopPropagation();
    this.sendBi();
  }

  sendBi() {
    const biLogger = getBiLogger();
    const { panelType, doc } = this.props;
    let linkUrl = '';
    let content = '';
    const docParts = doc ? doc.split(`[${READ_MORE_TEXT}]`) : [''];
    content = docParts[0];
    linkUrl = docParts[1] ? docParts[1].slice(1, -1) : '';

    biLogger.report(
      ideAutocompleteReadMoreClick({
        chosenOption: content,
        link_url: linkUrl,
        panel_type: panelType,
      }),
    );
  }

  render() {
    const { className } = this.props;

    return (
      <ScrollableWidgetDiv data-hook="documentation" className={className}>
        <div
          onClick={this.handleClick}
          className={style.documentationHtml}
          dangerouslySetInnerHTML={{ __html: this.getDocumentationHtml() }} // eslint-disable-line react/no-danger
        />
      </ScrollableWidgetDiv>
    );
  }
}
