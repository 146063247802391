import _ from 'lodash';
import palette from '../../../style/palette.scss';

type ColorsMap = {
  [colorName: string]: {
    hashtag: string;
    value: string;
  };
};

const colorsMap: ColorsMap = _.mapValues(palette, value => ({
  hashtag: value,
  value: value.replace('#', ''),
}));

export default colorsMap;
