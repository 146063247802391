import _ from 'lodash';
import { DEFAULT_LANGUAGE, CSS_DEFAULT_LANGUAGE, } from '../constants/languageConstants';
import { getEslintImportStatement } from '../../consts/path';
import { isExperimentOpen } from '../../services/providers/experimentsProvider';
import { experimentNames } from '../../constants/experiments';

const isCSSEditingEnabled = isExperimentOpen(
  experimentNames.CSS_EDITING_SUPPORT,
);
const createWorkerURL = (workerName: string, baseUrl: string): string =>
  URL.createObjectURL(
    new Blob([
      `
            self.MonacoEnvironment = {
                baseUrl: '${baseUrl}'
            };
            ${getEslintImportStatement({ baseUrl, workerName })}
        `,
    ]),
  );

export default (): void => {
  const baseUrl = _.get(
    window,
    'serviceTopology.scriptsLocationMap[wix-code-code-editor]',
  );

  // since we are using dynamic imports, several bundles will be deployed and loaded on-demand
  // thus, we want to make sure consumers from different hosts will load the bundles from the same host on the fly
  // eslint-disable-next-line
  __webpack_public_path__ =
    baseUrl && baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;

  window.MonacoEnvironment = {
    // we use only the second param of getWorkerUrl and noUnusedLocals complier option is throwing on build:app
    // @ts-expect-error
    getWorkerUrl(moduleId: string, label: string): string {
      if (label === 'json') {
        return createWorkerURL('json.worker', baseUrl);
      }
      if (label === 'typescript' || label === DEFAULT_LANGUAGE) {
        return createWorkerURL('ts.worker', baseUrl);
      }
      if (label === CSS_DEFAULT_LANGUAGE && isCSSEditingEnabled) {
        return createWorkerURL('css.worker', baseUrl);
      }
      return createWorkerURL('editor.worker', baseUrl);
    },
  };
  require('../patches');
  require('../patches/languages/setupTypescriptLanguageDefaults');
  if (isCSSEditingEnabled) {
    require('../patches/languages/setupCSSLanguageDefaults');
  }
};
