import _ from 'lodash';

const JSW_FILE_EXTENSION = '.jsw';
const JSON_FILE_EXTENSTION = '.json';

const removeExtention = path => path.replace(/\.[^/.]+$/, '');
const createBasicDefenition = moduleName => `declare module '${moduleName}';`;
const createJswDefenitions = path =>
  `${createBasicDefenition(path)} ${createBasicDefenition(
    removeExtention(path),
  )};`;

const isJsw = path => path.endsWith(JSW_FILE_EXTENSION);
const isJson = path => path.endsWith(JSON_FILE_EXTENSTION);

export default _.once(() => {
  const extraDefenitionsMap = {};

  const add = ({ path }) => {
    if (isJsw(path)) {
      extraDefenitionsMap[path] = createJswDefenitions(path);
    }
    if (isJson(path)) {
      extraDefenitionsMap[path] = createBasicDefenition(path);
    }
  };

  const remove = ({ path }) => {
    if (extraDefenitionsMap[path]) {
      delete extraDefenitionsMap[path];
    }
  };

  const getDeclarations = () => {
    return Object.keys(extraDefenitionsMap).map(libPath => ({
      path: libPath,
      content: extraDefenitionsMap[libPath],
    }));
  };

  return {
    add,
    remove,
    getDeclarations,
  };
});
