import * as monaco from 'monaco-editor-core';
import monarchTokensProvider from './monarchTokensProvider';

const _setMonarchTokensProvider = monaco.languages.setMonarchTokensProvider;

monaco.languages.setMonarchTokensProvider = (languageId, tokensProvider) => {
  if (languageId === 'javascript') {
    tokensProvider = monarchTokensProvider as monaco.languages.IMonarchLanguage;
  }
  return _setMonarchTokensProvider(languageId, tokensProvider);
};
