export default () => {
  return {
    addDefinitions: async () => {
      // app builder
    },
    removeDefinitions: async () => {
      // app builder
    },
  };
};
