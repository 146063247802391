import COLORS from './colors';
import { CUSTOM_WIX_CODE_TOKENS } from '../../../../monaco/constants/semanticTokens';
import { CustomTheme } from '../../../../main';

export const codeEditorDarkTheme = {
  colors: {
    'editor.background': COLORS.editorBGDark?.hashtag,
    'editor.foreground': COLORS.editorTextDark?.hashtag,
    'editorLineNumber.foreground': COLORS.editorLineNumberDark?.hashtag,
    'editorLineNumber.activeForeground':
      COLORS.editorFocusedLineNumberDark?.hashtag,
    'editor.wordHighlightStrongBackground':
      COLORS.editorWordHighlightStrongBackgroundDark?.hashtag,
    'editor.selectionBackground': COLORS.editorSelectionBackgroundDark?.hashtag,
    'editor.inactiveSelectionBackground':
      COLORS.editorInactiveHighlightTextBGDark?.hashtag,
    'editor.findMatchBackground': COLORS.editorFindMatchBackgroundDark?.hashtag,
    'editor.findMatchHighlightBackground':
      COLORS.editorFindMatchHighlightBackgroundDark?.hashtag,
    'editor.lineHighlightBorder': COLORS.editorLineHighlightDark?.hashtag,
    'editor.lineHighlightBackground': COLORS.editorLineHighlightDark?.hashtag,
    'editorIndentGuide.background':
      COLORS.editorIndentInactiveBackgroundDark?.hashtag,
    'editorIndentGuide.activeBackground':
      COLORS.editorIndentActiveBackgroundDark?.hashtag,
    'editor.rangeHighlightBackground': COLORS.editorRangeHighlightDark?.hashtag,
    'editor.rangeHighlightBorder': COLORS.editorRangeHighlightDark?.hashtag,
    'editorCursor.foreground': COLORS.editorCursor?.hashtag,
    'editorCursor.background': COLORS.editorCursor?.hashtag,
    'menu.foreground': COLORS.menuForegroundDark?.hashtag,
    'menu.background': COLORS.menuBackgroundDark?.hashtag,
    'menu.selectionForeground': COLORS.menuSelectionForegroundDark?.hashtag,
    'menu.selectionBackground': COLORS.menuSelectionBackgroundDark?.hashtag,
    focusBorder: COLORS.findReplaceFocusBorderDark?.hashtag,
    'input.background': COLORS.findReplaceInputBackgroundDark?.hashtag,
    'input.foreground': COLORS.findReplaceInputForegroundDark?.hashtag,
    'input.border': COLORS.findReplaceInputBorder?.hashtag,
    errorForeground: COLORS.findReplaceErrorIndication?.hashtag,
    'editorWidget.background': COLORS.findReplaceInputBackgroundDark?.hashtag,
    'editorWidget.foreground': COLORS.findReplaceWidgetForeground?.hashtag,
    'editorWidget.border': COLORS.findReplaceInputBackgroundDark?.hashtag,
    'editorError.foreground': COLORS.editorErrorDark?.hashtag,
    'editorWarning.foreground': COLORS.editorWarningDark?.hashtag,
    'editorOverviewRuler.errorForeground':
      COLORS.editorErrorOverviewRulerDark?.hashtag,
    'editorOverviewRuler.warningForeground':
      COLORS.editorWarningOverviewRulerDark?.hashtag,
    'peekViewEditor.matchHighlightBackground':
      COLORS.peekViewMatchHighlightBackgroundDark?.hashtag,
    'peekViewResult.background': COLORS.peekViewResultBackgroundDark?.hashtag,
    'peekViewResult.lineForeground': COLORS.peekViewResultColorDark?.hashtag,
    'peekViewResult.selectionForeground':
      COLORS.peekViewResultColorDark?.hashtag,
    'list.hoverBackground': COLORS.peekViewResultHoverBackgroundDark?.hashtag,
    'list.inactiveSelectionBackground':
      COLORS.peekViewResultHoverBackgroundDark?.hashtag,
    'peekViewEditor.background': COLORS.peekViewEditorBackgroundDark?.hashtag,
    'peekViewTitle.background': COLORS.peekViewTitleBackgroundDark?.hashtag,
    'peekViewTitleLabel.foreground': COLORS.peekViewTitleColorDark?.hashtag,
    'peekViewTitleDescription.foreground':
      COLORS.peekViewTitleColorDark?.hashtag,
    'editorLink.activeForeground':
      COLORS.editorLinkActiveForegroundDark?.hashtag,
    'editorGutter.foldingControlForeground':
      COLORS.editorFoldIconColorDark?.hashtag,
    'editorSuggestWidget.background':
      COLORS.suggestWidgetBackgroundDark?.hashtag,
    'editorSuggestWidget.foreground':
      COLORS.suggestWidgetForegroundDark?.hashtag,
    'editorSuggestWidget.border': COLORS.suggestWidgetBorderDark?.hashtag,
    'editorSuggestWidget.selectedBackground':
      COLORS.suggestWidgetSelectedBackgroundDark?.hashtag,
    'editorSuggestWidget.focusHighlightForeground':
      COLORS.suggestWidgetSelectedForegroundDark?.hashtag,
    'editorSuggestWidget.highlightForeground':
      COLORS.suggestWidgetHighlightForegroundDark?.hashtag,
  },
  rules: [
    { background: COLORS.editorBGDark },
    { token: '', foreground: COLORS.editorTextDark?.value },
    { token: 'comment', foreground: COLORS.editorTextCommentDark?.value },
    { token: 'string', foreground: COLORS.editorTextStringDark?.value },
    { token: 'number', foreground: COLORS.editorTextNumberDark?.value },
    {
      token: 'keyword',
      foreground: COLORS.editorTextKeywordDark?.value,
      fontStyle: 'bold',
    },
    { token: 'identifier', foreground: COLORS.editorIdentifierDark?.value },
    { token: 'type.identifier', foreground: COLORS.editorTextClassDark?.value },
    { token: 'delimiter', foreground: COLORS.editorTextPunctuationDark?.value },
    { token: 'tag.css', foreground: COLORS.editorTextStringDark?.value },
    {
      token: 'attribute.name.css',
      foreground: COLORS.editorFunctionCallDark?.value,
    },
    {
      token: 'attribute.value.css',
      foreground: COLORS.editorFunctionParameterDark?.value,
    },
    {
      token: 'attribute.value.number.css',
      foreground: COLORS.editorTextNumberDark?.value,
    },
    {
      token: 'attribute.value.unit.css',
      foreground: COLORS.editorTextNumberDark?.value,
    },
    {
      token: CUSTOM_WIX_CODE_TOKENS.FUNCTION_NAME,
      foreground: COLORS.editorFunctionNameDark?.value,
    },
    {
      token: CUSTOM_WIX_CODE_TOKENS.FUNCTION_PARAMETER,
      foreground: COLORS.editorFunctionParameterDark?.value,
    },
    {
      token: CUSTOM_WIX_CODE_TOKENS.FUNCTION_CALL,
      foreground: COLORS.editorFunctionCallDark?.value,
    },
    {
      token: CUSTOM_WIX_CODE_TOKENS.RETURN_STATEMENT,
      foreground: COLORS.editorReturnStatementDark?.value,
      fontStyle: 'bold',
    },
  ],
} as CustomTheme;
