import * as monaco from 'monaco-editor-core';
import isArray_ from 'lodash/isArray';

import { DEFAULT_LANGUAGE } from '../../constants/languageConstants';

const _register = monaco.languages.register;

monaco.languages.register = (
  definition: monaco.languages.ILanguageExtensionPoint,
): void => {
  if (definition.id === DEFAULT_LANGUAGE) {
    if (isArray_(definition.extensions)) {
      definition.extensions.push('.jsw');
    }
  }
  return _register(definition);
};
