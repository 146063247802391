import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default captureException => WrappedComponent => {
  class MonitoredComponent extends Component {
    getChildContext() {
      return {
        captureException: this.handleError,
      };
    }

    componentDidCatch(err, errorInfo) {
      this.handleError(err, errorInfo);
    }

    handleError(err, errorInfo) {
      captureException(err, { errorInfo });
      console.error(err); // eslint-disable-line no-console
    }

    render() {
      try {
        return <WrappedComponent {...this.props} />;
      } catch (ex) {
        this.handleError(ex);
      }
    }
  }

  MonitoredComponent.childContextTypes = {
    captureException: PropTypes.func,
  };

  return MonitoredComponent;
};
