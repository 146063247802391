import webBiLogger from '@wix/web-bi-logger';
import { UserContext, Logger } from '@wix/web-bi-logger/dist/src/types';

let _biLogger: Logger,
  _msid: string,
  _uuid: string,
  _viewMode: string,
  _builderType: string,
  _getBlocksAppId: () => string;

export const initBiLogger = () => {
  const factory = webBiLogger
    .factory()
    .setMuted(false)
    .withUserContext({
      uuid: () => _uuid,
      _viewMode: () => _viewMode,
      msid: () => _msid,
      builderType: () => _builderType,
      app_id: () =>
        _getBlocksAppId?.() || window.appStudioModel?.devSiteAppDefId,
    } as UserContext);

  _biLogger = factory.logger();
};

export interface BIContext {
  msid: string;
  uuid: string;
  viewMode: string;
  builderType: string;
  getBlocksAppId: () => string;
}

export const setBiContext = ({
  msid,
  uuid,
  viewMode,
  builderType,
  getBlocksAppId,
}: BIContext) => {
  _msid = msid;
  _uuid = uuid;
  _viewMode = viewMode;
  _builderType = builderType;
  _getBlocksAppId = getBlocksAppId;
};

export const getBiLogger = () => _biLogger;
