import COLORS from './colors';
import { CUSTOM_WIX_CODE_TOKENS } from '../../../../monaco/constants/semanticTokens';
import { CustomTheme } from '../../../../main';

export const codeEditorStudioTheme = {
  colors: {
    'editor.foreground': COLORS.editorTextStudio?.hashtag,
    'editor.background': COLORS.editorBGStudio?.hashtag,
    'editor.selectionBackground':
      COLORS.editorSelectionBackgroundStudio?.hashtag,
    'editorLineNumber.foreground': COLORS.editorLineNumberStudio?.hashtag,
    'editorLineNumber.activeForeground':
      COLORS.editorFocusedLineNumberStudio?.hashtag,
    'editorSuggestWidget.background':
      COLORS.suggestWidgetBackgroundStudio?.hashtag,
    'editorSuggestWidget.border': COLORS.suggestWidgetBorderStudio?.hashtag,
    'editorSuggestWidget.selectedForeground':
      COLORS.suggestWidgetSelectedForegroundStudio?.hashtag,
    'editorSuggestWidget.selectedBackground':
      COLORS.suggestWidgetSelectedBackgroundStudio?.hashtag,
    'editorSuggestWidget.highlightForeground':
      COLORS.suggestWidgetHighlightForegroundStudio?.hashtag,
    'editorSuggestWidget.focusHighlightForeground':
      COLORS.suggestWidgetFocusHighlightForegroundStudio?.hashtag,
  },
  rules: [
    { background: COLORS.editorBGStudio },
    { token: '', foreground: COLORS.editorTextStudio?.value },
    { token: 'comment', foreground: COLORS.editorTextCommentStudio?.value },
    { token: 'string', foreground: COLORS.editorTextStringStudio?.value },
    { token: 'number', foreground: COLORS.editorTextNumberStudio?.value },
    {
      token: 'keyword',
      foreground: COLORS.editorTextKeywordStudio?.value,
      fontStyle: 'bold',
    },
    { token: 'identifier', foreground: COLORS.editorIdentifierStudio?.value },
    {
      token: 'type.identifier',
      foreground: COLORS.editorTextClassStudio?.value,
    },
    {
      token: 'delimiter',
      foreground: COLORS.editorTextPunctuationStudio?.value,
    },
    { token: 'tag.css', foreground: COLORS.editorTextStringStudio?.value },
    {
      token: 'attribute.name.css',
      foreground: COLORS.editorFunctionCallStudio?.value,
    },
    {
      token: 'attribute.value.css',
      foreground: COLORS.editorFunctionParameterStudio?.value,
    },
    {
      token: 'attribute.value.number.css',
      foreground: COLORS.editorTextNumberStudio?.value,
    },
    {
      token: 'attribute.value.unit.css',
      foreground: COLORS.editorTextNumberStudio?.value,
    },
    {
      token: CUSTOM_WIX_CODE_TOKENS.FUNCTION_NAME,
      foreground: COLORS.editorFunctionNameStudio?.value,
    },
    {
      token: CUSTOM_WIX_CODE_TOKENS.FUNCTION_PARAMETER,
      foreground: COLORS.editorFunctionParameterStudio?.value,
    },
    {
      token: CUSTOM_WIX_CODE_TOKENS.FUNCTION_CALL,
      foreground: COLORS.editorFunctionCallStudio?.value,
    },
    {
      token: CUSTOM_WIX_CODE_TOKENS.RETURN_STATEMENT,
      foreground: COLORS.editorReturnStatementStudio?.value,
      fontStyle: 'bold',
    },
  ],
} as CustomTheme;
