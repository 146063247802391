import { debounce } from 'lodash';
import * as monaco from 'monaco-editor-core';
import { AnyFixMe, Possible } from '../../main';
import { getBiLogger } from '../../services/bi';
import {
  ideAutocompletePanelShow,
  ideAutocompleteUse,
} from '@wix/bi-logger-platform/v2';
import { Languages } from '../monaco';
import getCurrentModel from '../../services/providers/currentModelProvider';
import { getEditor } from '../../services/providers/editorProvider';
import { ORIGINS } from './consts';

interface CompletionsEventsParams {
  editor: monaco.editor.IStandaloneCodeEditor;
}

interface SuggestController extends monaco.editor.IEditorContribution {
  model: {
    onDidSuggest: (
      callback: (e: {
        auto: boolean;
        completionModel: { _filteredItems: { word: string }[] };
      }) => void,
    ) => monaco.IDisposable;
  };
}

export enum TriggerOrigin {
  AUTO = 'auto',
  MANUAL = 'manual',
}

export const COMPLETION_PANEL_TYPE = 'completions';
export const SUGGEST_CONTROLLER_CONTRIBUTION_NAME =
  'editor.contrib.suggestController';

export const completionsEventsCreator = (
  params?: Possible<CompletionsEventsParams>,
) => {
  const biLogger = getBiLogger();
  const sendFocusBiEvent = debounce(
    (word: string = '', origin: TriggerOrigin) => {
      biLogger.report(
        ideAutocompletePanelShow({
          character_count: word.length,
          origin,
          panel_type: COMPLETION_PANEL_TYPE,
          user_input: word,
        }),
      );
    },
    1000,
  );

  const registerPanelFocusEvent = (): monaco.IDisposable => {
    return (
      params?.editor.getContribution(
        SUGGEST_CONTROLLER_CONTRIBUTION_NAME,
      ) as SuggestController
    ).model.onDidSuggest(e => {
      sendFocusBiEvent(
        e.completionModel._filteredItems[0]?.word as string,
        e.auto ? TriggerOrigin.AUTO : TriggerOrigin.MANUAL,
      );
    });
  };

  const getWorkerClient = async (model: monaco.editor.ITextModel) => {
    const worker = await (
      monaco.languages as typeof Languages
    ).typescript.getJavaScriptWorker();
    const resource = model.uri.toString();
    return worker(resource);
  };

  const getCurrentOffset = (model: monaco.editor.ITextModel) => {
    const position = getEditor()?.getPosition();
    const validPosition = model.validatePosition(position as monaco.Position);
    return model.getOffsetAt(validPosition);
  };

  const isECMAOrigin = (entryDetails: any) => {
    return (
      entryDetails.kindModifiers === 'declare' ||
      ['globalThis', 'undefined'].includes(entryDetails.name)
    );
  };

  const getLocalOrECMAOrigin = async (completionLabel: string) => {
    const model = getCurrentModel();
    const client = await getWorkerClient(model);
    const offset = getCurrentOffset(model);
    const entryDetails = await client.getCompletionEntryDetails(
      model.uri.toString(),
      offset,
      completionLabel,
    );

    let origin: Possible<string> = null;
    if (isECMAOrigin(entryDetails)) {
      origin = ORIGINS.ECMASCRIPT;
    } else {
      origin = ORIGINS.LOCAL;
    }

    return origin;
  };

  const sendCompletionChosenEvent = async (e: AnyFixMe) => {
    const eventItem = e?.item;

    if (!eventItem) {
      return;
    }

    const userInput = eventItem.word;
    const completionItem = eventItem.completion;

    if (completionItem.origin === ORIGINS.LOCAL_OR_ECMA) {
      completionItem.origin = await getLocalOrECMAOrigin(completionItem.label);
    }

    biLogger.report(
      ideAutocompleteUse({
        category: completionItem.origin || '',
        chosenOption: completionItem.label,
        character_count: userInput.length.toString(),
        file_path: completionItem.uri?.path || '',
        panel_type: COMPLETION_PANEL_TYPE,
        user_input: userInput,
      }),
    );
  };

  return {
    registerPanelFocusEvent,
    sendCompletionChosenEvent,
  };
};
