import * as monaco from 'monaco-editor-core';
import themeService from './themeService/theme';
import constants from '../constants';
import { AnyFixMe } from '../../../main';

export default ({
  model,
  element,
  theme,
}: AnyFixMe): monaco.editor.IStandaloneCodeEditor => {
  themeService.defineThemes();

  return monaco.editor.create(element, {
    parameterHints: {
      enabled: true,
    },
    quickSuggestions: { comments: false, strings: true },
    automaticLayout: true,
    model,
    glyphMargin: true,
    hover: {
      enabled: false,
    },
    theme: themeService.getEditorTheme({ theme }),
    fontSize: 12,
    minimap: {
      enabled: false,
    },
    scrollbar: constants.apricot.scrollbarSettings,
    contextmenu: true,
  });
};
