import * as monaco from 'monaco-editor-core';
import { Possible } from '../../main';

let initializedEditor: Possible<monaco.editor.IStandaloneCodeEditor> = null;

export const getEditor = (): Possible<monaco.editor.IStandaloneCodeEditor> =>
  initializedEditor;

export const initEditorProvider = (
  editor: monaco.editor.IStandaloneCodeEditor,
): void => {
  initializedEditor = editor;
};
