import { Component, Children } from 'react';
import PropTypes from 'prop-types';

const experimentsHelper = (experiments = {}) => ({
  isOpen(experiment) {
    return ['new', 'true'].includes(experiments[experiment]);
  },
});

export default class ExperimentsProvider extends Component {
  constructor(props, context) {
    super(props, context);
    this.experiments = experimentsHelper(props.experiments);
  }

  getChildContext() {
    return { experiments: this.experiments };
  }

  render() {
    return Children.only(this.props.children);
  }
}

ExperimentsProvider.childContextTypes = {
  experiments: PropTypes.object.isRequired,
};
