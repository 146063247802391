import * as monaco from 'monaco-editor-core';
import { CSS_DEFAULT_LANGUAGE } from '../../../monaco/constants/languageConstants';
import { getCustomCodeValidator } from '../../../api/validators/customCodeValidators';
import { CSS_LINT_MODEL_MARKERS_OWNER } from '../constants';
import { CustomCodeValidator } from '../../..';

const getCssLintManager = (editor: monaco.editor.IStandaloneCodeEditor) => {
  let validator: CustomCodeValidator | undefined;
  const refreshModelMarkers = () => {
    validator = validator ?? getCustomCodeValidator(CSS_DEFAULT_LANGUAGE);
    const model = editor.getModel();
    // make sure that the model was not disposed in the meantime
    if (
      !model ||
      model.isDisposed() ||
      model.getModeId() !== CSS_DEFAULT_LANGUAGE
    ) {
      return;
    }
    const modelContent = model.getValue();
    monaco.editor.setModelMarkers(model, CSS_LINT_MODEL_MARKERS_OWNER, []);
    if (validator) {
      const markers = validator(modelContent, model.uri.path);
      monaco.editor.setModelMarkers(
        model,
        CSS_LINT_MODEL_MARKERS_OWNER,
        markers,
      );
    }
  };
  return {
    refreshModelMarkers,
  };
};

export { getCssLintManager };