import COLORS from './colors';
import { CUSTOM_WIX_CODE_TOKENS } from '../../../../monaco/constants/semanticTokens';
import { CustomTheme } from '../../../../main';

export const codeEditorLightTheme = {
  colors: {
    'editor.foreground': COLORS.editorText?.hashtag,
    'editor.background': COLORS.editorBG?.hashtag,
    'editorLineNumber.foreground': COLORS.editorLineNumber?.hashtag,
    'editorLineNumber.activeForeground':
      COLORS.editorFocusedLineNumber?.hashtag,
    'editor.wordHighlightStrongBackground':
      COLORS.editorWordHighlightStrongBackground?.hashtag,
    'editor.selectionBackground': COLORS.editorSelectionBackground?.hashtag,
    'editor.inactiveSelectionBackground':
      COLORS.editorInactiveHighlightTextBG?.hashtag,
    'editor.findMatchBackground': COLORS.editorFindMatchBackground?.hashtag,
    'editor.findMatchHighlightBackground':
      COLORS.editorFindMatchHighlightBackground?.hashtag,
    'editorWarning.foreground': COLORS.editorWarning?.hashtag,
    'editor.lineHighlightBorder': COLORS.editorLineHighlight?.hashtag,
    'editor.lineHighlightBackground': COLORS.editorLineHighlight?.hashtag,
    'editorIndentGuide.background':
      COLORS.editorIndentInactiveBackground?.hashtag,
    'editorIndentGuide.activeBackground':
      COLORS.editorIndentActiveBackground?.hashtag,
    'editor.selectionHighlightBackground':
      COLORS.editorSelectionHighlight?.hashtag,
    'editor.selectionHighlightBorder': COLORS.editorSelectionHighlight?.hashtag,
    'editorCursor.foreground': COLORS.editorText?.hashtag,
    'editorCursor.background': COLORS.editorText?.hashtag,
    'editorSuggestWidget.background': COLORS.suggestWidgetBackground?.hashtag,
    'editorSuggestWidget.foreground': COLORS.suggestWidgetForeground?.hashtag,
    'editorSuggestWidget.border': COLORS.suggestWidgetBorder?.hashtag,
    'editorSuggestWidget.selectedBackground':
      COLORS.suggestWidgetSelectedBackground?.hashtag,
    'editorSuggestWidget.focusHighlightForeground':
      COLORS.suggestWidgetSelectedForeground?.hashtag,
    'editorSuggestWidget.highlightForeground':
      COLORS.suggestWidgetHighlightForeground?.hashtag,
  },
  rules: [
    { background: COLORS.editorBG },
    { token: '', foreground: COLORS.editorText?.value },
    { token: 'comment', foreground: COLORS.editorTextComment?.value },
    { token: 'string', foreground: COLORS.editorTextString?.value },
    { token: 'number', foreground: COLORS.editorTextNumber?.value },
    {
      token: 'keyword',
      foreground: COLORS.editorTextKeyword?.value,
      fontStyle: 'bold',
    },
    { token: 'identifier', foreground: COLORS.editorIdentifier?.value },
    { token: 'type.identifier', foreground: COLORS.editorTextClass?.value },
    { token: 'delimiter', foreground: COLORS.editorTextPunctuation?.value },
    { token: 'tag.css', foreground: COLORS.editorTextString?.value },
    {
      token: 'attribute.name.css',
      foreground: COLORS.editorFunctionCall?.value,
    },
    {
      token: 'attribute.value.css',
      foreground: COLORS.editorFunctionParameter?.value,
    },
    {
      token: 'attribute.value.number.css',
      foreground: COLORS.editorTextNumber?.value,
    },
    {
      token: 'attribute.value.unit.css',
      foreground: COLORS.editorTextNumber?.value,
    },
    {
      token: CUSTOM_WIX_CODE_TOKENS.FUNCTION_NAME,
      foreground: COLORS.editorFunctionName?.value,
    },
    {
      token: CUSTOM_WIX_CODE_TOKENS.FUNCTION_PARAMETER,
      foreground: COLORS.editorFunctionParameter?.value,
    },
    {
      token: CUSTOM_WIX_CODE_TOKENS.FUNCTION_CALL,
      foreground: COLORS.editorFunctionCall?.value,
    },
    {
      token: CUSTOM_WIX_CODE_TOKENS.RETURN_STATEMENT,
      foreground: COLORS.editorReturnStatement?.value,
      fontStyle: 'bold',
    },
  ],
} as CustomTheme;
