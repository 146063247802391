import * as monaco from 'monaco-editor-core';
import _ from 'lodash';
import viewStateManagerCreator from './viewStateManager';
import internalModelsApi from '../../../api/externalModels/internalModelsApi';
import decorationsProviderManager from '../../../api/decorations/decorationsProviderManager';
import { getBiLogger } from '../../../services/bi';
import { ideClickOnLinkInTheCode } from '@wix/bi-logger-platform/v2';
import toggleCodeValidation from './toggleCodeValidation';
import createUserEventsCallbacks from './userEventsCallbacks';

export default ({ editor, onReadyOnlyStateChange }) => {
  const biLogger = getBiLogger();

  const { suppressUserEvents, onSelection, onFocus, onBlur, onLinkNavigated } =
    createUserEventsCallbacks({
      editor,
    });

  onLinkNavigated(url =>
    biLogger.report(
      ideClickOnLinkInTheCode({
        link_url: url,
        title: url,
        file_path: editor.getModel().uri.path,
      }),
    ),
  );

  const getSelection = () => {
    const selection = editor.getSelection();
    if (!selection) {
      return;
    }

    const { startLineNumber, startColumn, endLineNumber, endColumn } =
      selection;
    return {
      start: { line: startLineNumber, column: startColumn },
      end: { line: endLineNumber, column: endColumn },
    };
  };

  const setSelection = selection => {
    const currentSelection = getSelection();

    if (
      !selection ||
      !currentSelection ||
      _.isEqual(currentSelection, selection)
    ) {
      return;
    }

    const {
      start: { line: startLineNumber, column: startColumn },
      end: { line: endLineNumber, column: endColumn },
    } = selection;

    editor.setSelection({
      startLineNumber,
      startColumn,
      endLineNumber,
      endColumn,
    });

    editor.revealLineInCenter(startLineNumber - 1);
  };

  const setModel = modelId => {
    const model = internalModelsApi.getModel({ modelId });
    editor.setModel(model);
  };

  const { registerDecorationsProvider } = decorationsProviderManager(editor);

  const viewStateManager = viewStateManagerCreator({ editor });

  const updateState = ({
    oldModelId,
    newModelId,
    selection,
    hideErrors,
    readOnly,
  }) => {
    const modelChanged = oldModelId !== newModelId;
    const editorReadOnly = editor.getOption(
      monaco.editor.EditorOption.readOnly,
    );

    if (modelChanged) {
      viewStateManager.save(oldModelId);
      setModel(newModelId);
      viewStateManager.load(newModelId);
    }
    if (editorReadOnly !== readOnly) {
      onReadyOnlyStateChange(readOnly);
      editor.updateOptions({ readOnly });
    }

    suppressUserEvents(() => setSelection(selection));
    toggleCodeValidation(!hideErrors);
  };

  return {
    onSelection,
    onFocus,
    onBlur,
    onLinkNavigated,
    updateState,
    registerDecorationsProvider,
  };
};
