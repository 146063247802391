import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import { getAppVersion } from './version';

const environment = process.env.NODE_ENV;
const defaultIntegrationsToExclude = ['GlobalHandlers', 'ReportingObserver'];

const initSentry = ({ dsn }) => {
  const sentryOptions = {
    dsn,
    attachStacktrace: true,
    includePaths: [/wix-code-code-editor/, /codeEditor/],
    release: getAppVersion(),
    environment,
    enabled: environment === 'production',
    integrations: Sentry.defaultIntegrations.filter(
      integration => !defaultIntegrationsToExclude.includes(integration.name),
    ),
  };

  const client = new Sentry.BrowserClient(sentryOptions);
  const hub = new Sentry.Hub(client);

  const captureException = (err, { errorInfo } = {}) => {
    hub.run(currentHub => {
      currentHub.withScope(scope => {
        if (errorInfo) {
          Object.keys(errorInfo).forEach(key =>
            scope.setExtra(key, errorInfo[key]),
          );
        }
        return currentHub.captureException(err);
      });
    });
  };

  try {
    // will be removed as soon as we get the proper api from the editor
    if (window.editorModel) {
      hub.configureScope(scope => {
        scope.setUser({
          id: _.get(window.editorModel, 'permissionsInfo.loggedInUserId'),
          roles: _.get(window.editorModel, 'permissionsInfo.loggedInUserRoles'),
        });

        const { metaSiteId, publicUrl, editorVersion } = window.editorModel;

        scope.setTags({
          metaSiteId,
          publicUrl,
          editorVersion,
        });
      });
    }
  } catch (ex) {
    captureException(ex, {
      reason: 'Error while setting santa-editor context',
    });
  }

  return {
    captureException,
  };
};

export { initSentry };
