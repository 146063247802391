import _ from 'lodash';
import { WIX_CODE_SELECTOR } from '../constants/languageConstants';

export const COMPLETION_TYPE_ORDER = {
  IMPORT_TYPE: '1',
  WIX_CODE_SELECTOR: '2',
  WIX_CODE_ID_SELECTOR: '3',
  PREDEFINED_SNIPPETS: '4',
};

export const ORIGINS = {
  LOCAL: 'local',
  WIXSDK: 'wixsdk',
  WIX_APIS: 'Wix APIs',
  ECMASCRIPT: 'ecmascript',
  TEMPLATES: 'Templates',
  LOCAL_OR_ECMA: 'localOrEcma',
};

export const labelGetters = {
  getWixSelectorLabel: () => WIX_CODE_SELECTOR,
  getWixSelectorLabelByid: (id: string) => `${WIX_CODE_SELECTOR}('#${id}')`,
  getWixModuleImportStatement: (module: string) => {
    const moduleName = _.camelCase(module);
    const label = `import ${moduleName} from '${module}';`;
    return label;
  },
};

export const docGetters = {
  getWixCodeSelectorDoc: () => 'Selects and returns elements from a page.',
  getWixCodeSelectorByIdDoc: (id: string, type: string) =>
    `Selects ${id}, a ${type}`,
};

export const typeGetters = {
  getWixCodeSelectorType: () => 'any',
  getSnippetType: () => 'snippet',
};
