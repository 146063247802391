import React, { Component } from 'react';
import classNames from 'classnames';
import style from './LintErrors.scss';
import { getBiLogger } from '../../services/bi';
import { ideCodeErrorWarningPanelShow } from '@wix/bi-logger-platform/v2';
import * as monaco from 'monaco-editor-core';
import _ from 'lodash';
import withExperiments from '../Experiments/withExperiments';

const getClassNames = (markers, index) =>
  classNames({
    [style.lintErrorMarker]: true,
    [style.bottomBorder]: markers.length > 1 && index < markers.length - 1,
  });

class LintErrors extends Component {
  componentDidMount() {
    const { markers, editor } = this.props;

    const biLogger = getBiLogger();

    markers.forEach(marker => {
      const type =
        marker.severity === monaco.MarkerSeverity.Error ? 'error' : 'warning';

      const position = {
        column: marker.startColumn,
        lineNumber: marker.startLineNumber,
      };

      const wordObject = editor.getModel().getWordAtPosition(position);
      const line = _.get(position, 'lineNumber');
      const lineContent = editor.getModel().getLineContent(line);

      biLogger.report(
        ideCodeErrorWarningPanelShow({
          line,
          file_path: _.get(marker, 'resource.path'),
          message_text: _.get(marker, 'message'),
          line_of_code: lineContent,
          message_code: _.get(marker, 'code'),
          panel_type: type,
          user_input: _.get(wordObject, 'word'),
        }),
      );
    });
  }

  render() {
    const { markers, dataHook } = this.props;

    return (
      <div>
        {markers.map((marker, index) => (
          <div key={`marker_${marker.message}_${index}`}>
            <div data-hook={dataHook} className={getClassNames(markers, index)}>
              {marker.message}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const LinErrorsWithExperiments = withExperiments(LintErrors);
export { LinErrorsWithExperiments as LintErrors };
