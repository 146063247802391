import React from 'react';
import PropTypes from 'prop-types';

export default function withExperiments(WrappedComponent) {
  const ExperimentsWrapper = (props, context) => {
    const extraProps = { experiments: context.experiments };
    const finalProps = { ...props, ...extraProps };
    return React.createElement(WrappedComponent, finalProps);
  };

  ExperimentsWrapper.WrappedComponent = WrappedComponent;

  ExperimentsWrapper.contextTypes = {
    experiments: PropTypes.object,
  };

  return ExperimentsWrapper;
}
