import * as monaco from 'monaco-editor-core';
import _ from 'lodash';

const CONTEXT_MAX_LENGTH = 20;
const searchApi = getModels => {
  const search = ({ query, options }) => {
    const hasOption = option => options.includes(option);

    const getResultTitle = (res, model) => {
      const getPrefix = (range, prefixModel) => {
        const startPosition = range.getStartPosition();
        const lineHead = prefixModel.getValueInRange(
          monaco.Range.fromPositions(
            new monaco.Position(startPosition.lineNumber, 0),
            startPosition,
          ),
        );
        const pattern = new RegExp(`\\W?(\\w*.{0,${CONTEXT_MAX_LENGTH}})$`);
        const match = lineHead.match(pattern);
        return match ? match[1] : '';
      };

      const getSuffix = (range, suffixModel) => {
        if (monaco.Range.spansMultipleLines(range)) {
          return '';
        }
        const endPosition = range.getEndPosition();
        const lineTail = suffixModel.getValueInRange(
          monaco.Range.fromPositions(
            endPosition,
            new monaco.Position(
              endPosition.lineNumber,
              suffixModel.getLineMaxColumn(endPosition.lineNumber),
            ),
          ),
        );
        const pattern = new RegExp(`^(.{0,${CONTEXT_MAX_LENGTH}}\\w*)\\W?`);
        const match = lineTail.match(pattern);
        return match ? match[1] : '';
      };
      const range = res.range;
      const match = model.getValueInRange(range);
      const prefix = getPrefix(range, model);
      const suffix = getSuffix(range, model);
      return {
        prefix,
        match,
        suffix,
      };
    };

    const filterWords = (matches, model) => {
      const doesRangeMatchFullWord = match => {
        const title = getResultTitle(match, model);
        return /(\W$|^$)/.test(title.prefix) && /(^\W|^$)/.test(title.suffix);
      };
      return matches.filter(doesRangeMatchFullWord);
    };

    const results = getModels().map(model => {
      const matches = model
        .findMatches(
          query,
          false,
          hasOption('regex_active'),
          hasOption('case_sensitive'),
          monaco.editor.wordSeparators,
          true,
        )
        .map(res =>
          Object.assign(res, {
            modelId: model.uri.toString(),
            resultTitle: getResultTitle(res, model),
          }),
        );
      return hasOption('match_word') ? filterWords(matches, model) : matches;
    });
    return _.flatten(results);
  };

  return search;
};

export default searchApi;
