import normalizeUrl from 'normalize-url';

const isValidURL = (url: string) => {
  try {
    new URL(url);

    return true;
  } catch {
    return false;
  }
};
interface EslintImportStatementParams {
  baseUrl: string;
  workerName: string;
}

export const getEslintImportStatement = ({
  baseUrl,
  workerName,
}: EslintImportStatementParams) => {
  let workerUrl = `${baseUrl}/static/js/${workerName}.js`;
  if (isValidURL(workerUrl)) {
    workerUrl = normalizeUrl(workerUrl);
  }

  return `importScripts('${workerUrl}');`;
};
