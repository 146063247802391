import * as monaco from 'monaco-editor-core';
import { DEFAULT_LANGUAGE } from '../../constants/languageConstants';

const _setLanguageConfiguration = monaco.languages.setLanguageConfiguration;

monaco.languages.setLanguageConfiguration = (
  languageId: string,
  conf: monaco.languages.LanguageConfiguration,
): monaco.IDisposable => {
  if (languageId === DEFAULT_LANGUAGE) {
    // Intentionally leave out "#" although Monaco ships with it, since it screws up code completion with nicknames
    conf.wordPattern = /(-?\d*\.\d\w*)|([^`~!@%^&*()=+[{\]}\\|;:'",.<>/?\s]+)/g;
  }

  return _setLanguageConfiguration(languageId, conf);
};
