import getCurrentModel from '../../../services/providers/currentModelProvider';
import {
  ComponentService,
  ComponentServiceParams,
  Possible,
} from '../../../main';
import {
  WIX_CODE_SELECTOR,
  COMPONENT_SELECTOR_PREFIX,
} from '../../../monaco/constants/languageConstants';
import { Languages } from '../../../monaco/monaco';
import ts from 'typescript';
import * as monaco from 'monaco-editor-core';

export const highlightingComponentsServiceCreator = ({
  selectComponentByNickname,
  deselectComponents,
}: ComponentServiceParams): ComponentService => {
  let selectedComponent: Possible<string> = null;

  const getSignatureHelpItems = async (
    position: monaco.Position,
  ): Promise<Possible<ts.SignatureHelpItems>> => {
    const worker = await (
      monaco.languages as typeof Languages
    ).typescript.getJavaScriptWorker();
    const model = getCurrentModel();
    const resource = model.uri;
    const validPosition = model.validatePosition(position);
    const offset = model.getOffsetAt(validPosition);
    const client = await worker(resource);
    const signatureHelpItems: Possible<ts.SignatureHelpItems> =
      await client.getSignatureHelpItems(resource.toString(), offset, {});

    return signatureHelpItems;
  };

  const deselectComponentsAndClearSelected = async () => {
    await deselectComponents();
    selectedComponent = '';
  };

  const handleComponentSelection = async (
    signatureHelpItems: Possible<ts.SignatureHelpItems>,
  ): Promise<void> => {
    const displayParts: ts.SymbolDisplayPart[] =
      signatureHelpItems?.items[0]?.parameters[0]?.displayParts || [];
    const nicknameDisplayPart: Possible<ts.SymbolDisplayPart> =
      displayParts.find(
        (p: ts.SymbolDisplayPart) =>
          p.text.includes(COMPONENT_SELECTOR_PREFIX) &&
          p.kind === 'stringLiteral',
      );

    if (!nicknameDisplayPart) {
      await deselectComponentsAndClearSelected();
      return;
    }

    const componentId = nicknameDisplayPart.text;
    if (componentId === selectedComponent) {
      return;
    }

    await selectComponentByNickname(componentId);
    selectedComponent = componentId;
  };

  const handleCursorSelectionEvent = async (
    event: monaco.editor.ICursorPositionChangedEvent,
  ): Promise<void> => {
    if (event.reason !== monaco.editor.CursorChangeReason.Explicit) {
      return;
    }

    const signatureHelpItems = await getSignatureHelpItems(event.position);
    const prefixDisplayParts: Possible<ts.SymbolDisplayPart[]> =
      signatureHelpItems?.items[0]?.prefixDisplayParts;

    if (!prefixDisplayParts) {
      await deselectComponentsAndClearSelected();
      return;
    }

    const has$wPart = prefixDisplayParts.some(
      (part: ts.SymbolDisplayPart) => part.text === WIX_CODE_SELECTOR,
    );
    if (has$wPart) {
      await handleComponentSelection(signatureHelpItems);
    } else {
      await deselectComponentsAndClearSelected();
    }
  };

  return { handleCursorSelectionEvent };
};
