import _ from 'lodash';
import React, { Component } from 'react';
import ExperimentsProvider from '../Experiments/ExperimentsProvider';
import { WixCodeCodeContextProvider } from '../context/WixCodeCodeContext';

const Editor = React.lazy(() => import('../Editor/Editor'));

export default ({ typingsLoader }) =>
  class Root extends Component {
    render() {
      const editorProps = _.omit(this.props, ['experiments']);

      return (
        <ExperimentsProvider experiments={this.props.experiments}>
          <WixCodeCodeContextProvider theme={this.props.theme}>
            <React.Suspense fallback={<div />}>
              <Editor {...editorProps} typingsLoader={typingsLoader} />
            </React.Suspense>
          </WixCodeCodeContextProvider>
        </ExperimentsProvider>
      );
    }
  };
