import { SuggestController } from 'monaco-editor-core/esm/vs/editor/contrib/suggest/suggestController';
import { AnyFixMe } from '../../../main';
import { completionsEventsCreator } from '../../monacoCompletions/completionsEvents';

const applySendingCustomBiEventWhileCompletionChosen = () => {
  const _insertSuggestion = SuggestController.prototype._insertSuggestion;

  SuggestController.prototype._insertSuggestion = function (
    event: AnyFixMe,
    flags: AnyFixMe,
  ) {
    const completionsEventsManager = completionsEventsCreator();
    completionsEventsManager.sendCompletionChosenEvent(event);
    _insertSuggestion.call(this, event, flags);
  };
};

applySendingCustomBiEventWhileCompletionChosen();
