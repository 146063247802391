import * as monaco from 'monaco-editor-core';
import _ from 'lodash';
import { decorationTypes } from './decorationTypes';

const DEBOUNCE_TIMEOUT = 100;

const MONACO_GLYPH_DECORATIONS_CLASS_NAMES = ['cgmr', 'codicon'];

const removeMonacoClassesFromClassList = classList =>
  classList
    .toString()
    .split(' ')
    .filter(
      className => !MONACO_GLYPH_DECORATIONS_CLASS_NAMES.includes(className),
    );
const isValidDecorationType = decorationType =>
  !!decorationTypes[decorationType];

const monacoMouseTypeToDecorationTypeMap = {
  [monaco.editor.MouseTargetType.GUTTER_GLYPH_MARGIN]:
    decorationTypes.LEFT_MARGIN,
  [monaco.editor.MouseTargetType.CONTENT_TEXT]: decorationTypes.INLINE,
};

const monacoMouseTypeToDecorationType = monacoMouseType =>
  monacoMouseTypeToDecorationTypeMap[monacoMouseType];

const getDeltaDecorationOptions = (decorationType, cssClassName) => {
  if (decorationType === decorationTypes.INLINE) {
    return {
      inlineClassName: cssClassName,
    };
  } else {
    return {
      glyphMarginClassName: cssClassName,
    };
  }
};

const currentDecorations = {};
class DecorationsProviderManager {
  constructor({ editor }) {
    this.decorationsProviders = new Set();
    this.editor = editor;
    this.decorationsEvents = [];
    this.editorListenerDisposables = [];
    this.isInitialized = false;
    this.currentDecorations = {};

    this.disposeProvider.bind(this);
    this.updateDecorations.bind(this);
    this.registerProvider.bind(this);
    this.updateDecorationsHandler = _.debounce(
      this.updateDecorations.bind(this),
      DEBOUNCE_TIMEOUT,
    ).bind(this);
  }

  init() {
    this.editor.onDidChangeModelContent(this.updateDecorationsHandler);
    this.editor.onDidChangeModel(this.updateDecorationsHandler);
    this.isInitialized = true;
  }

  startListeners() {
    this.listenToEditorEvents();
  }

  isEmptyProviders() {
    return this.decorationsProviders.size === 0;
  }

  /**
   * @async
   * @callback DecorationsProvider
   * @param {Object} decorationProviderArgs
   * @param {string} decorationProviderArgs.modelId Gets the current modelId
   * @param {function} decorationProviderArgs.getModelValue Gets the current model value/text.
   * @returns {Array<Decoration>}
   *
   * @method registerProvider Registers a new decoration provider that will stack on existing ones
   * @param {DecorationsProvider} registerProvider
   * @returns {Object}  Returns { dispose() }
   *
   * @example
   * const { dispose } = decorationsProviderManager.registerProvider(
   *    ({modelId, getModelValue }) => {
   *    const modelValue = getModelValue()  // Gets the current model value
   *    return [
   *      range: [1, 1, 1, 1],                    // Range [lineStart, columnStart, lineEnd, columnEnd]
   *      className: 'anotherCustomGlyphMargin',  // Css class name
   *      type: 'LEFT_MARGIN',                    // Decoration Type (currently LEFT_MARGIN and INLINE is supported)
   *      events: [                               // List of editor Events. Currently only 'click' events are supported
   *          {
   *            type: 'click',
   *            callback:(event) => {             // Callback that will be triggered when class matches
   *               ...
   *            }
   *          }
   *        }
   *    ]
   * })
   */
  registerProvider(decorationsProvider) {
    if (!this.isInitialized) {
      this.init();
    }
    if (this.isEmptyProviders()) {
      this.startListeners();
    }
    this.decorationsProviders.add(decorationsProvider);
    this.updateDecorationsHandler();

    return {
      dispose: () => this.disposeProvider(decorationsProvider),
    };
  }

  disposeProvider(decorationsProvider) {
    this.decorationsProviders.delete(decorationsProvider);
    if (this.isEmptyProviders()) {
      this.disposeListeners();
    }
    this.updateDecorationsHandler();
  }

  listenToEditorEvents() {
    let _previousElementMouseDown;

    this.editorListenerDisposables.push(
      this.editor.onMouseDown(event => {
        _previousElementMouseDown = event.target.element;
      }),
    );
    this.editorListenerDisposables.push(
      this.editor.onMouseUp(event => {
        const decorationType = monacoMouseTypeToDecorationType(
          event.target.type,
        );
        if (
          decorationType &&
          event.target.element === _previousElementMouseDown
        ) {
          this.invokeAllCallbacksForEventType({
            decorationType,
            eventType: 'click',
            event,
          });
        }
      }),
    );
  }

  disposeListeners() {
    this.editorListenerDisposables.forEach(disposable => disposable.dispose());
    this.editorListenerDisposables = [];
  }

  getDecorationEventsByClassContainingPosition({
    decorationType,
    eventType,
    className,
    position,
  }) {
    return this.decorationsEvents.filter(
      decorationEvent =>
        decorationEvent.decorationType === decorationType &&
        decorationEvent.eventType === eventType &&
        decorationEvent.className === className &&
        decorationEvent.range.containsPosition(position),
    );
  }

  invokeAllCallbacksForEventType({ decorationType, eventType, event }) {
    const elementProviderClasses = removeMonacoClassesFromClassList(
      event.target.element.classList,
    );
    elementProviderClasses.forEach(className => {
      this.getDecorationEventsByClassContainingPosition({
        decorationType,
        eventType,
        className,
        position: event.target.position,
      }).forEach(({ callback }) => callback(event));
    });
  }

  extractEventFromDecoration(decoration) {
    const decorationType = _.get(decoration, 'type');
    const cssDecorationClassName = _.get(decoration, 'className');
    if (cssDecorationClassName && isValidDecorationType(decorationType)) {
      const glyphMarginClassEvents = _.get(decoration, 'events', []);

      return glyphMarginClassEvents.map(({ type, callback }) => ({
        decorationType,
        eventType: type,
        callback,
        className: cssDecorationClassName,
        range: new monaco.Range(...decoration.range),
      }));
    }
  }

  addCallbacksFromDecorations(decorations) {
    this.decorationsEvents = this.decorationsEvents.concat(
      _.compact(decorations.map(this.extractEventFromDecoration)).flat(),
    );
  }

  decorationToDeltaDecoration(decoration) {
    const decorationType = _.get(decoration, 'type');
    const cssDecorationClassName = _.get(decoration, 'className');
    if (cssDecorationClassName && isValidDecorationType(decorationType)) {
      const range = new monaco.Range(...decoration.range);
      const options = getDeltaDecorationOptions(
        decorationType,
        cssDecorationClassName,
      );
      return {
        range,
        options,
      };
    }
    return null;
  }

  async updateDecorations() {
    const model = this.editor.getModel();
    if (!model || model.isDisposed()) {
      return;
    }

    const modelId = model.uri.toString();

    this.decorationsEvents = [];
    const deltaDecorations = [];
    for (const decorationsProvider of this.decorationsProviders) {
      const { decorations } = await decorationsProvider({
        modelId,
        getModelValue: () => model.getValue(),
      });
      this.addCallbacksFromDecorations(decorations);
      deltaDecorations.push(
        _.compact(decorations.map(this.decorationToDeltaDecoration)),
      );
    }

    // Needed in order to reset monaco decorations for a modelId,
    // otherwise monaco will attempt to guess the new position of the decoration
    // with weird side effects
    currentDecorations[modelId] = await model.deltaDecorations(
      currentDecorations[modelId],
      deltaDecorations.flat(),
    );
  }
}

export default editor => {
  const decorationsProviderManager = new DecorationsProviderManager({
    editor,
  });
  return {
    registerDecorationsProvider: decorationsProvider =>
      decorationsProviderManager.registerProvider(decorationsProvider),
  };
};
