import {
  contextDeclarationsServiceCreator,
  wixModulesServiceCreator,
  ContextDeclarationService,
  WixModulesService,
} from '@wix/wix-code-editor-types';
import { FileContext } from '@wix/wix-code-common';
import { initWixModulesCompletions } from '../../../monaco/monacoCompletions/wixModules';

const wixCodeEditorTypesServiceCreator = () => {
  let wixModulesService: WixModulesService;
  let editorTypesLoaderPromise: Promise<void>;

  const waitForEditorTypesLoadingCompletion = () => editorTypesLoaderPromise;

  const setModulesCompletions = async (context: FileContext) => {
    await waitForEditorTypesLoadingCompletion();
    const modules = wixModulesService.getModules(context);
    initWixModulesCompletions(modules);
  };

  const init = (
    cb: (declarationsService: ContextDeclarationService) => Promise<void>,
    isBetaAutoComplete: boolean = false,
  ): void => {
    wixModulesService = wixModulesServiceCreator(isBetaAutoComplete);
    const contextDeclarationsService =
      contextDeclarationsServiceCreator(isBetaAutoComplete);
    editorTypesLoaderPromise = Promise.all([
      wixModulesService.init(),
      contextDeclarationsService.init(),
    ]).then(() => {
      cb(contextDeclarationsService);
    });
  };

  return {
    init,
    wixModules: {
      setModulesCompletions,
    },
  };
};

const wixCodeEditorTypesService = wixCodeEditorTypesServiceCreator();

export default wixCodeEditorTypesService;
