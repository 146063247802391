import React, { Component } from 'react';
import classNames from 'classnames';

import s from './ScrollableWidgetDiv.scss';

export default class ScrollableWidgetDiv extends Component {
  constructor() {
    super();
    this.updateScroll = this.updateScroll.bind(this);
    this.setRef = this.setRef.bind(this);
  }

  updateScroll(e) {
    e.preventDefault();
    e.stopPropagation();

    this.divComponent.scrollTop += e.deltaY;
  }

  componentDidMount() {
    this.divComponent.addEventListener('wheel', this.updateScroll);
  }

  componentWillUnmount() {
    this.divComponent.removeEventListener('wheel', this.updateScroll);
  }

  setRef(nativeComponent) {
    this.divComponent = nativeComponent;
    this.props.forwardedRef(nativeComponent);
  }

  render() {
    const { className, children, forwardedRef, ...otherProps } = this.props;
    return (
      <div
        className={classNames(s.scrollable, className)}
        ref={this.setRef}
        {...otherProps}
      >
        {children}
      </div>
    );
  }
}

ScrollableWidgetDiv.defaultProps = {
  forwardedRef: () => {},
};
