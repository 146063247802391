import React, { useEffect } from 'react';
import TypeSignature from '../TypeSignature/TypeSignature';
import { Documentation } from '../Documentation/Documentation';
import s from './TypeDetails.scss';
import { getBiLogger } from '../../services/bi';
import { ideAutocompletePanelShow } from '@wix/bi-logger-platform/v2';
import _ from 'lodash';
import cx from 'classnames';
import { useWixCodeCodeContext } from '../context/WixCodeCodeContext';

const PANEL_TYPE = 'hover_content';

interface TypeDetailsProps {
  name: string;
  type: number;
  doc: string;
}

const TypeDetails = (props: TypeDetailsProps): JSX.Element => {
  const { theme } = useWixCodeCodeContext();
  const { type, name, doc } = props;
  useEffect(() => {
    const biLogger = getBiLogger();

    biLogger.report(
      ideAutocompletePanelShow({
        character_count: _.get(name, 'length', 0),
        origin: 'hover',
        panel_type: PANEL_TYPE,
        user_input: name,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-hook="type-details">
      <div className={cx(s.typeDetailsDocumentation, s[theme])}>
        <TypeSignature type={type} name={name} />
      </div>
      {doc && (
        <Documentation
          doc={doc}
          className={cx(s.typeDetailsDocumentation, s[theme])}
          panelType={PANEL_TYPE}
        />
      )}
    </div>
  );
};

export default TypeDetails;
