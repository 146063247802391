import * as monaco from 'monaco-editor-core';
import { DEFAULT_LANGUAGE } from '../../../monaco/constants/languageConstants';
import {
  EslintManager,
  EslintManagerParams,
  RegisterLintMessagesEventListenerParams,
} from '../../../main';
import { getEslintImportStatement } from '../../../consts/path';

const createESLintWebWorker = (baseUrl: string): Worker => {
  const workerName = 'eslint.worker';
  const eslintWorkerCode = getEslintImportStatement({ baseUrl, workerName });
  const blobURL = URL.createObjectURL(new Blob([eslintWorkerCode]));
  return new Worker(blobURL);
};

const registerLintMessagesEventListener = ({
  worker,
  editor,
}: RegisterLintMessagesEventListenerParams) => {
  worker.addEventListener('message', ({ data }) => {
    const { markers, version } = data;
    const model = editor.getModel();

    if (model && model.getVersionId() === version) {
      monaco.editor.setModelMarkers(model, 'eslint', markers);
    }
  });
};

export default ({ editor, baseUrl }: EslintManagerParams): EslintManager => {
  const worker = createESLintWebWorker(baseUrl);
  registerLintMessagesEventListener({ editor, worker });

  const refreshModelMarkers = (): void => {
    const model = editor.getModel();
    // make sure that the model was not disposed in the meantime
    if (!model || model.isDisposed()) {
      return;
    }

    const modelContent = model.getValue();
    monaco.editor.setModelMarkers(model, 'eslint', []);
    const version = model.getVersionId();
    if (model.getModeId() === DEFAULT_LANGUAGE) {
      worker.postMessage({ content: modelContent, version });
    }
  };

  const dispose = () => worker.terminate();

  return {
    refreshModelMarkers,
    dispose,
  };
};
