import * as monaco from 'monaco-editor-core';
import runBeautify from '../../../features/runBeautify';
import findReplaceApiCreator from './findReplaceApi';
import deprecatedDefinitionsApiCreator from './deprecatedTypeDefinitionApi';
import { AnyFixMe, CodeEditorExternalAPI } from '../../../main';

interface CodeEditorExternalApiCreator {
  editor: monaco.editor.IStandaloneCodeEditor;
  internalApi: AnyFixMe;
  onFindReplaceToggle: AnyFixMe;
}

export const KeyBindings = {
  KeyCode: monaco.KeyCode,
  KeyMod: monaco.KeyMod,
};

export default ({
  editor,
  internalApi,
  onFindReplaceToggle,
}: CodeEditorExternalApiCreator): { api: CodeEditorExternalAPI.API } => {
  const { registerDecorationsProvider } = internalApi;
  const findReplaceApi = findReplaceApiCreator({ editor, onFindReplaceToggle });
  const definitionsApi = deprecatedDefinitionsApiCreator();

  const resize = () => {
    return editor.layout();
  };

  const format = () => {
    return runBeautify(editor);
  };

  // TODO: remove undo/redo
  // see link: https://github.com/microsoft/monaco-editor/issues/451
  const redo = () => {
    // @ts-expect-error
    return editor.getModel().redo();
  };

  const undo = () => {
    // @ts-expect-error
    return editor.getModel().undo();
  };

  const focus = () => {
    return editor.focus();
  };

  const addCommand: monaco.editor.IStandaloneCodeEditor['addCommand'] = (
    ...args
  ) => {
    return editor.addCommand(...args);
  };

  return {
    api: {
      ...findReplaceApi,
      ...definitionsApi,
      resize,
      format,
      redo,
      undo,
      focus,
      getEditorPrototype: () => Object.getPrototypeOf(editor),
      registerDecorationsProvider,
      addCommand,
    },
  };
};
