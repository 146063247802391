export const DEFAULT_LANGUAGE = 'javascript';
export const CSS_DEFAULT_LANGUAGE = 'css';
export const WIX_CODE_SELECTOR = '$w';
export const COMPONENT_SELECTOR_PREFIX = '#';

export default {
  DEFAULT_LANGUAGE,
  WIX_CODE_SELECTOR,
  COMPONENT_SELECTOR_PREFIX,
  CSS_DEFAULT_LANGUAGE
};
