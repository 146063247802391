import { getBiLogger } from '../../../services/bi';
import { idePasteInjectedCode } from '@wix/bi-logger-platform/v2';
import * as monaco from 'monaco-editor-core';

const blockActions = editor => {
  const noop = () => {};
  // command palette
  editor.addCommand(monaco.KeyCode.F1, noop);
  // go to line
  editor.addCommand(monaco.KeyMod.WinCtrl | monaco.KeyCode.KEY_G, noop);
  // override browser save webpage command
  editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, noop);
  // select next match (multi-cursor feature)
  editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_D, noop);
};

const addCommands = ({ editor, onFindInAllFiles }) => {
  editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_Y, function () {
    editor.getModel().redo();
  });
  editor.addCommand(
    monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KEY_F,
    onFindInAllFiles,
  );

  editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Space, () => {
    editor.trigger('', 'editor.action.triggerSuggest', {});
  });
};

const initElementCopyEvent = (editor, element) => {
  // subscribe to copy events on the code editor element
  // rather than adding cmd+c command to cover edge cases
  element.addEventListener('copy', async () => {
    const selection = editor.getSelection();
    const isCopyingEntireLine =
      selection.startColumn === selection.endColumn &&
      selection.startLineNumber === selection.endLineNumber;
    const model = editor.getModel();
    const value = isCopyingEntireLine
      ? model.getLineContent(selection.startLineNumber)
      : model.getValueInRange(selection);
    const biLogger = getBiLogger();
    await biLogger.report(
      idePasteInjectedCode({
        code: value,
        character_count: value.length,
        file_path: model.uri.path,
        action: 'copy',
      }),
    );
  });
};

export default ({ element, editor, onFindInAllFiles, experiments }) => {
  addCommands({
    editor,
    onFindInAllFiles,
    experiments,
  });
  blockActions(editor);
  initElementCopyEvent(editor, element);
};
