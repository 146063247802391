const parseAppUrl = () => {
  const dummyError = new Error();
  const stack = dummyError.stack.toString();
  const versionRegex = new RegExp(
    /https?:\/\/.*wix-code-code-editor\/(\d+\.\d+\.\d+)\//,
  );
  const match = versionRegex.exec(stack);

  return {
    baseUrl: match ? match[0] : '',
    version: match ? match[1] : 'unknown',
  };
};

const getAppVersion = () => {
  const { version } = parseAppUrl();
  return version;
};

export { getAppVersion };
