const modelsViewState = new Map();

export default ({ editor }) => {
  const save = modelId => modelsViewState.set(modelId, editor.saveViewState());
  const load = modelId => {
    if (!modelsViewState.has(modelId)) {
      return;
    }

    editor.restoreViewState(modelsViewState.get(modelId));
  };

  return {
    save,
    load,
  };
};
