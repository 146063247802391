'use strict'

const acorn = require('acorn')
const acornLoose = require('acorn-loose')
const estraverse = require('estraverse')
const formatCode = require('js-beautify').js_beautify
const UserError = require('../UserError')
const defaultFormattingOptions = {
  indent_size: 1,
  indent_char: '\t',
  indent_with_tabs: true,
  jslint_happy: true,
  indent_inner_html: true,
  max_preserve_newlines: 2,
  preserve_newlines: true,
  good_stuff: true,
  selector_separator_newline: true
}
const acornOptions = { sourceType: 'module', ranges: true, locations: true, ecmaVersion: 'latest' }
const ACRON_LOOSE_INVALID_NODE_NAME = '✖' // copid from acroon loose github read.me
const isValidNodeName = (name) => name !== ACRON_LOOSE_INVALID_NODE_NAME

function isReturnStatement(declaration) {
  return declaration.type === 'ReturnStatement'
}

function isExportDeclaration(declaration) {
  return declaration.type === 'ExportNamedDeclaration' || declaration.type === 'ExportDefaultDeclaration'
}

function isExportNamedDeclaration(declaration) {
  return declaration.type === 'ExportNamedDeclaration'
}

function isFunctionDeclaration(declaration) {
  return declaration.type === 'FunctionDeclaration'
}

function isVariableDeclaration(declaration) {
  return declaration.type === 'VariableDeclaration'
}

function compareExportDeclarations(declaration, otherDeclaration) {
  return declaration.type === otherDeclaration.type && declaration.id.name === otherDeclaration.id.name
}

function isArrowFunction(declaration) {
  return declaration.init && declaration.init.type && declaration.init.type === 'ArrowFunctionExpression'
}

function handleExportedNode(exportedNode, callback) {
  if (isFunctionDeclaration(exportedNode)) {
    callback(exportedNode)
  } else if (isVariableDeclaration(exportedNode)) {
    exportedNode.declarations.forEach(function (declaration) {
      callback(declaration)
    })
  }
}

function forEachExportStatementDeclaration(programAst, callback) {
  estraverse.traverse(programAst, {
    enter: (node) => {
      if (isExportDeclaration(node)) {
        handleExportedNode(node.declaration, callback)
      }
    }
  })
}

function exportStatementDeclarationExists(programAst, comparator) {
  let found = false

  forEachExportStatementDeclaration(programAst, function (declaration) {
    if (comparator(declaration)) {
      found = true
      return estraverse.VisitorOption.Break
    }
  })

  return found
}

function getCaretOffset(initialOffset, exportedNode) {
  if (isFunctionDeclaration(exportedNode)) {
    return initialOffset + exportedNode.body.range[0] + 1
  } else if (isVariableDeclaration(exportedNode)) {
    return initialOffset + exportedNode.range[1] - 1
  }

  //Last ditch effort just put it at the end of the node.
  return initialOffset + exportedNode.end
}

function validateExportNode(exportNode, exportStatement, programAst) {
  if (!isExportDeclaration(exportNode)) {
    throw new UserError('Provided: `' + exportStatement + '` is not a export statement.')
  } else if (exportStatementDeclarationExists(programAst, compareExportDeclarations.bind(this, exportNode.declaration))) {
    throw new UserError('An export statement with the given name: `' + exportNode.declaration.id.name + '` already exists.')
  }
}

function parseCode(code) {
  try {
    return acorn.parse(code, acornOptions)
  } catch (e) {
    throw new UserError('Error parsing code', e)
  }
}
function parseCodeLoose(code) {
  try {
    return acornLoose.parse(code, acornOptions)
  } catch (e) {
    // parsing with acornLoose shouldn't fail, if it fails unexpectedly we do nothing
  }
}

function addExport(code, exportStatement, formattingOptions) {
  const formattedCode = '\n\n' + formatCode(exportStatement, formattingOptions || defaultFormattingOptions)
  const exportNode = parseCode(formattedCode).body[0]
  let lineOffset = 1

  let ast
  try {
    ast = parseCode(code)
  } catch (e) {
    // The parsing have failed, we add the export any way it's up to the user to make sure to not duplicate the code.
  }

  if (ast) {
    validateExportNode(exportNode, exportStatement, ast)

    // Only assign line offset if code is parsable
    lineOffset = ast.loc.end.line
  }

  return {
    newCode: code + formattedCode,
    diff: {
      code: formattedCode,
      insertAt: code.length
    },
    caretOffset: getCaretOffset(code.length, exportNode.declaration),
    caretLocation: getCaretLocation(exportNode.declaration, lineOffset - 1, 1)
  }
}

function getCaretLocation(exportedNode, initialLineOffset = 0, initialColumnOffset = 0) {
  const { body: blockStatement } = exportedNode
  const { loc: location = {} } = blockStatement

  if (isFunctionDeclaration(exportedNode)) {
    const caretLocation = Object.assign({}, location.start, { column: location.start.column + initialColumnOffset + 1,
      line: location.start.line + initialLineOffset })
    return caretLocation
  } else if (isVariableDeclaration(exportedNode)) {
    return Object.assign({}, location, { start: location.end })
  }

  //Last ditch effort just put it at the end of the node.
  return location
}

function isDeclarationWithName(exportDeclarationName, declaration) {
  return exportDeclarationName === declaration.id.name
}

function exportStatementExists(code, exportDeclarationName) {
  const ast = parseCode(code)

  return exportStatementDeclarationExists(ast, isDeclarationWithName.bind(this, exportDeclarationName))
}

function isDeclarationWithNamePrefix(declaration, exportStatementPrefix) {
  function startsWith(haystack, needle) {
    return haystack.slice(0, needle.length) === needle
  }

  return declaration.id && declaration.id.name && startsWith(declaration.id.name, exportStatementPrefix)
}

function exportStatementsWithPrefix(code, exportStatementPrefix) {
  const ast = parseCode(code)
  const foundStatements = []

  forEachExportStatementDeclaration(ast, function (declaration) {
    if (isDeclarationWithNamePrefix(declaration, exportStatementPrefix)) {
      foundStatements.push(declaration.id.name)
    }
  })

  return foundStatements
}

function getCaretInfoForExportStatement(code, exportStatementName) {
  const ast = parseCode(code)
  let caretOffset = 0
  let caretLocation = { line: 1, column: 1 }
  let found = false

  forEachExportStatementDeclaration(ast, function (declaration) {
    if (isDeclarationWithName(exportStatementName, declaration)) {
      caretOffset = getCaretOffset(0, declaration)
      caretLocation = getCaretLocation(declaration, 0, 1)

      found = true

      return estraverse.VisitorOption.Break
    }
  })

  return {
    found,
    caretOffset,
    caretLocation
  }
}

function renameExportStatement(code, oldExportStatementName, newExportStatementName) {
  const { found, caretOffset, caretLocation } = getCaretInfoForExportStatement(code, oldExportStatementName)
  const newCode = found ? code.replace(oldExportStatementName, newExportStatementName) : null
  const newCaretOffset = found ? caretOffset + (newExportStatementName.length - oldExportStatementName.length) : null
  const offsetDiff = newExportStatementName.length - oldExportStatementName.length
  const newCaretLocation = found ? Object.assign(caretLocation, { column: caretLocation.column + offsetDiff }) : null

  return {
    newCode,
    found,
    caretOffset: newCaretOffset,
    caretLocation: newCaretLocation
  }
}

// Deprecated. Please use listExportedFunctions instead. To be Removed
function listNamedExportedFunctionDeclarations(code) {
  const ast = parseCodeLoose(code)
  const foundDeclarations = []

  estraverse.traverse(ast, {
    enter: (node) => {
      if (isExportDeclaration(node) && node.declaration && isFunctionDeclaration(node.declaration)) {
        foundDeclarations.push({
          name: node.declaration.id.name,
          location: [node.loc.start.line, node.loc.start.column],
          paramNames: node.declaration.params.map(
            ({ name, left: { name: leftName } = {} }) =>
              name || leftName || null
          )
        })
      }
    }
  })

  return foundDeclarations

}

function listExportedFunctions(code) {
  const ast = parseCodeLoose(code)
  const foundDeclarations = []

  estraverse.traverse(ast, {
    enter: (node) => {
      if (isExportNamedDeclaration(node) && node.declaration) {
        if (node.declaration && isFunctionDeclaration(node.declaration)) {
          foundDeclarations.push({
            name: node.declaration.id.name,
            location: [node.loc.start.line, node.loc.start.column],
            paramNames: node.declaration.params.map(
              ({
                name,
                left: {
                  name: leftName
                } = {}
              }) =>
              name || leftName || null
            )
          })
        } else if (isVariableDeclaration(node.declaration) &&
          node.declaration.declarations &&
          node.declaration.declarations[0] && isArrowFunction(node.declaration.declarations[0])) {
          foundDeclarations.push({
            name: node.declaration.declarations[0].id.name,
            location: [node.loc.start.line, node.loc.start.column],
            paramNames: node.declaration.declarations[0].init.params.map(
              ({
                name,
                left: {
                  name: leftName
                } = {}
              }) =>
              name || leftName || null
            )
          })
        }
      }

    }
  })

  return foundDeclarations

}

function getParamInfo(node) {
  const {
    name, loc,
    left: { name: leftName, loc: locLeft } = {},
    argument: { name: argumentName, loc:  argumentLoc } = {},
    properties
  } = node
  
  if (leftName) { // param with default value
    return {
      name: leftName,
      location: locLeft
    }
  } else if (argumentName) { // spread param
    return {
      name: argumentName,
      location:  argumentLoc
    }
  } else if (properties && properties.length) { // destructured param
    return properties
      .map(prop => ({
        name: prop.key.name,
        location: prop.key.loc
      }))
  } else { // regular param
    return {
      name,
      location: loc
    }
  }
}

function isReturnStatementValidNode(node) {
  const { type: nodeType, loc: nodeLocation } = node
  return !!nodeType && nodeLocation && isReturnStatement(node)
}

function isFunctionDeclarationValidNode(node) {
  const {
    type: nodeType,
    loc: nodeLocation,
    id: nodeId
  } = node
  const nodeIdName = nodeId && nodeId.name

  return !!nodeType && nodeLocation && nodeIdName && isValidNodeName(nodeIdName) && isFunctionDeclaration(node)
}

function listFunctionsDeclarationsInfo(code) {
  const ast = parseCodeLoose(code)
  const declarations = []
  const returnStatements = []

  if (ast) {
    estraverse.traverse(ast, {
      enter: (node) => {
        if (isFunctionDeclarationValidNode(node)) {
          declarations.push({
            name: node.id.name,
            location: node.id.loc,
            params: node.params
              .map(getParamInfo)
              .reduce((res, p) => res.concat(p), [])
          })
        } else if (isReturnStatementValidNode(node)) {
          returnStatements.push({
            location: node.loc
          })
        }
      },
      fallback: 'iteration'
    })
  }
  

  return { declarations, returnStatements }
}

module.exports = {
  getCaretInfoForExportStatement,
  exportStatementsWithPrefix,
  exportStatementExists,
  addExport,
  renameExportStatement,
  listNamedExportedFunctionDeclarations,
  listFunctionsDeclarationsInfo,
  listExportedFunctions
}
