import { InMemoryStorageService } from 'monaco-editor-core/esm/vs/platform/storage/common/storage';

const applyShowingSuggestionDetailsByDefault = () => {
  const SUGGESTION_DETAILS_WIDGET_KEY = 'expandSuggestionDocs';
  const _getBoolean = InMemoryStorageService.prototype.getBoolean;
  InMemoryStorageService.prototype.getBoolean = function (
    key: string,
    scope: number,
    fallbackValue: boolean,
  ) {
    if (key === SUGGESTION_DETAILS_WIDGET_KEY) {
      fallbackValue = true;
    }
    return _getBoolean.call(this, key, scope, fallbackValue);
  };
};

applyShowingSuggestionDetailsByDefault();
