import * as monaco from 'monaco-editor-core';

interface ContextMenuConstants {
  GROUP: {
    NAVIGATION: string;
    MODIFICATION: string;
    CUT_COPY_PASTE: string;
  };
}

interface EditorConstants {
  CONTEXT_MENU: ContextMenuConstants;
  apricot: {
    scrollbarSettings: monaco.editor.IEditorScrollbarOptions;
  };
}

const Constants: EditorConstants = {
  CONTEXT_MENU: {
    /** The context menu of the editor has these default:
     *   navigation - The navigation group comes first in all cases.
     *   1_modification - This group comes next and contains commands that modify your code.
     *   9_cutcopypaste - The last default group with the basic editing commands.
     **/
    GROUP: {
      NAVIGATION: 'navigation',
      MODIFICATION: '1_modification',
      CUT_COPY_PASTE: '9_cutcopypaste',
    },
  },
  apricot: {
    scrollbarSettings: {
      useShadows: false,
      verticalHasArrows: false,
      vertical: 'auto',
      verticalScrollbarSize: 8,
      arrowSize: 0,
      horizontalScrollbarSize: 8,
    },
  },
};

export const CSS_LINT_MODEL_MARKERS_OWNER = 'csslint';

export default Constants;
