import * as monaco from 'monaco-editor-core';
import { modelIdToMonacoUri } from '../utils';

const getModel = ({ modelId }) => {
  if (!modelId) {
    throw new Error(`Missing modelId: ${modelId}`);
  }
  const model = monaco.editor.getModel(modelIdToMonacoUri(modelId));
  if (!model) {
    throw new Error(
      `A model with the specified URI (${modelId}) doesn't exist`,
    );
  }
  return model;
};

const update = ({ modelId, value }) => {
  const model = getModel({ modelId });
  if (model.getValue() === value) {
    return;
  }

  model.pushEditOperations(
    [],
    [
      {
        range: model.getFullModelRange(),
        text: value,
      },
    ],
  );
};

export default { getModel, update };
