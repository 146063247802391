export const definitionsApiCreator = ({ typingsLoader }) => {
  return {
    add: ({ fileName, content }) => {
      return typingsLoader.addDefinition({ fileName, content });
    },
    remove: ({ fileName }) => {
      return typingsLoader.removeDefinition({ fileName });
    },
  };
};
