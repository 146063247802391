import _ from 'lodash';
import {
  getUriFromMediaManagerPayload as getImageUriFromMediaManagerPayload,
  isWixImageUri,
  getUrl as getImageUrl,
} from '@wix/cm-media-utils/lib/image';
import {
  getUriFromMediaManagerPayload as getVideoUriFromMediaManagerPayload,
  isWixVideoUri,
  getPosterUri,
} from '@wix/cm-media-utils/lib/video';
import {
  getUriFromMediaManagerPayload as getVectorUriFromMediaManagerPayload,
  isWixVectorUri,
  getUrl as getVectorUrl,
} from '@wix/cm-media-utils/lib/vector';
import {
  mediaTypes,
  getMediaTypeByUri,
} from '@wix/cm-media-utils/lib/mediaItem';

export const MEDIA_MANAGER_TYPES = {
  IMAGE: 'IMAGE',
  VECTOR_IMAGE: 'VECTOR_IMAGE',
  VIDEO: 'VIDEO',
  BACKGROUND: 'BACKGROUND',
};

export const MEDIA_MANAGER_DOC = {
  [MEDIA_MANAGER_TYPES.IMAGE]:
    'Open the media manager to select one of your images',
  [MEDIA_MANAGER_TYPES.VECTOR_IMAGE]:
    'Open the media manager to select one of your vectors',
  [MEDIA_MANAGER_TYPES.BACKGROUND]:
    'Open the media manager to select one of your images or videos',
};

export const getUriFromMediaManagerPayload = (item, mediaManagerPayload) => {
  switch (item.mediaManagerType) {
    case MEDIA_MANAGER_TYPES.IMAGE:
      return getImageUriFromMediaManagerPayload(mediaManagerPayload);
    case MEDIA_MANAGER_TYPES.VECTOR_IMAGE:
      return getVectorUriFromMediaManagerPayload(mediaManagerPayload);
    case MEDIA_MANAGER_TYPES.BACKGROUND:
      return _.get(mediaManagerPayload, 'mediaType') === 'video'
        ? getVideoUriFromMediaManagerPayload(mediaManagerPayload)
        : getImageUriFromMediaManagerPayload(mediaManagerPayload);
    default:
      return;
  }
};

export const getUrlFromMediaManagerPayload = (
  mediaItemTypes,
  mediaManagerPayload,
) => {
  switch (mediaItemTypes) {
    case mediaTypes.IMAGE:
      return getImageUriFromMediaManagerPayload(mediaManagerPayload);
    case mediaTypes.VECTOR:
      return getVectorUriFromMediaManagerPayload(mediaManagerPayload);
    case mediaTypes.VIDEO:
      return getVideoUriFromMediaManagerPayload(mediaManagerPayload);
    default:
      return '';
  }
};

export const resolveWixUri = (uri, w, h) => {
  if (isWixImageUri(uri)) {
    return getImageUrl(uri, w, h);
  }
  if (isWixVideoUri(uri)) {
    return getImageUrl(getPosterUri(uri), w, h);
  }
  if (isWixVectorUri(uri)) {
    return getVectorUrl(uri);
  }
  return uri;
};

export const getMediaItemType = uri => {
  const supportedMediaTypes = [
    mediaTypes.IMAGE,
    mediaTypes.VIDEO,
    mediaTypes.VECTOR,
  ];
  const type = getMediaTypeByUri(uri);

  return supportedMediaTypes.includes(type) ? type : null;
};
