import * as monaco from 'monaco-editor-core';
import { WixCodeTheme } from '../../../../constants/wixCodeTheme';
import { codeEditorDarkTheme } from './codeEditorDarkTheme';
import { codeEditorLightTheme } from './codeEditorLightTheme';
import { CustomTheme } from '../../../../main';
import COLORS from './colors';
import { codeEditorStudioTheme } from './codeEditorStudioTheme';

const MONACO_THEMES = {
  WIX_CODE_IDE_DARK_THEME: 'wix-code-ide-dark-theme',
  WIX_CODE_IDE_LIGHT_THEME_FACELIFT: 'wix-code-ide-light-theme-facelift',
  WIX_CODE_STUDIO_IDE_THEME: 'wix-code-studio-ide-theme',
};

const defineTheme = (name: string, { colors, rules }: CustomTheme) => {
  monaco.editor.defineTheme(name, {
    // can also be vs-dark or hc-black
    base: 'vs',
    // can also be false to completely replace the builtin rules
    inherit: false,
    colors,
    rules,
  });
};

const updateThemeToClassicFacelift = (theme: CustomTheme): CustomTheme =>
  ({
    colors: {
      ...theme.colors,
      'editor.background': COLORS.editorBgFacelift?.hashtag,
    },
    rules: theme.rules,
  } as CustomTheme);

const defineThemes = (): void => {
  defineTheme(MONACO_THEMES.WIX_CODE_IDE_DARK_THEME, codeEditorDarkTheme);
  defineTheme(
    MONACO_THEMES.WIX_CODE_IDE_LIGHT_THEME_FACELIFT,
    updateThemeToClassicFacelift(codeEditorLightTheme),
  );
  defineTheme(MONACO_THEMES.WIX_CODE_STUDIO_IDE_THEME, codeEditorStudioTheme);
};

const themeConditions = {
  isDarkTheme: (theme: WixCodeTheme) => theme === WixCodeTheme.DARK,
  isStudioTheme: (theme: WixCodeTheme) => theme === WixCodeTheme.STUDIO,
};

interface ThemeOptions {
  theme: WixCodeTheme;
}

const themeGetters = {
  getNewColorTheme: ({ theme }: ThemeOptions) => {
    if (themeConditions.isDarkTheme(theme)) {
      return MONACO_THEMES.WIX_CODE_IDE_DARK_THEME;
    } else if (themeConditions.isStudioTheme(theme)) {
      return MONACO_THEMES.WIX_CODE_STUDIO_IDE_THEME;
    }
    return MONACO_THEMES.WIX_CODE_IDE_LIGHT_THEME_FACELIFT;
  },
};

const getEditorTheme = ({ theme }: ThemeOptions): string =>
  themeGetters.getNewColorTheme({ theme });

const setEditorTheme = (themeOptions: ThemeOptions): void => {
  monaco.editor.setTheme(getEditorTheme(themeOptions));
};

export default {
  defineThemes,
  getEditorTheme,
  setEditorTheme,
};
