import { ContextDeclarationService } from '@wix/wix-code-editor-types';
import internalModelsApi from '../../api/externalModels/internalModelsApi';
import { init as initModelContextProvider } from '../../services/providers/modelContextProvider';
import getCurrentModel, {
  init as initCurrentModelProvider,
} from '../../services/providers/currentModelProvider';
import { initEditorProvider } from '../../services/providers/editorProvider';
import { isExperimentOpen } from '../../services/providers/experimentsProvider';
import { experimentNames } from '../../constants/experiments';
import initEditorActions from './services/editorActions';
import codeEditorManagerCreator from './services/codeEditorManager';
import externalApiCreator from './services/codeEditorExternalApi';
import initEditorCommands from './services/editorCommands';
import wixCodeEditorTypesService from './services/wixCodeEditorTypesService';
import initFileContextManager from './services/fileContextManager';
import initEslintManager from './services/eslintManager';
import { getCssLintManager } from './services/cssLintManager';
import registerEditorEvents from './services/registerEditorEvents';
import createMonacoEditor from './services/createMonacoEditor';
import viewStateManagerCreator from './services/viewStateManager';
import {
  EditorActions,
  InitializeEditorParams,
  InitializeEditor,
} from '../../main';

export default ({
  modelId,
  element,
  getContext,
  onFindReplaceToggle,
  onFindInAllFiles,
  onSeeAllShortcuts,
  experiments,
  baseUrl,
  librariesLoadedCb,
  typingsLoader,
  theme,
  selectComponentByNickname,
  deselectComponents,
}: InitializeEditorParams): InitializeEditor => {
  const model = internalModelsApi.getModel({ modelId });

  const editor = createMonacoEditor({
    model,
    element,
    theme,
  });

  initModelContextProvider(getContext);
  initCurrentModelProvider(editor);
  initEditorProvider(editor);

  const eslintManager = initEslintManager({
    editor,
    baseUrl,
  });
  const cssLintManager = getCssLintManager(editor);

  const fileContextManager = initFileContextManager({
    typingsLoader,
    getContext,
    experiments,
  });

  const editorEvents = registerEditorEvents({
    editor,
    fileContextManager,
    eslintManager,
    cssLintManager,
    selectComponentByNickname,
    deselectComponents,
    experiments,
  });

  const onReadyOnlyStateChange = (readOnly: boolean) =>
    editorActions.update(readOnly); // eslint-disable-line @typescript-eslint/no-use-before-define

  const internalApi = codeEditorManagerCreator({
    editor,
    onReadyOnlyStateChange,
  });

  const { api: externalApi } = externalApiCreator({
    editor,
    internalApi,
    onFindReplaceToggle,
  });

  initEditorCommands({
    element,
    editor,
    onFindInAllFiles,
    experiments,
  });

  const editorActions = initEditorActions({
    editor,
    externalApi,
    onSeeAllShortcuts,
  }) as EditorActions;

  const onWixCodeEditorTypesLoaded = async (
    contextDeclarations: ContextDeclarationService,
  ) => {
    fileContextManager.setContextDeclarations(contextDeclarations);

    await fileContextManager.waitForWorkerAndLoadFileContext().then(() => {
      eslintManager.refreshModelMarkers();
      librariesLoadedCb();
    });
  };

  const isBetaAutoComplete = isExperimentOpen(
    experimentNames.BETA_AUTOCOMPLETE,
  );
  wixCodeEditorTypesService.init(
    onWixCodeEditorTypesLoaded,
    isBetaAutoComplete,
  );

  editorActions.update();

  const viewStateManager = viewStateManagerCreator({ editor });
  viewStateManager.load(modelId);

  return {
    dispose: () => {
      const currentModel = getCurrentModel();
      if (currentModel) {
        viewStateManager.save(currentModel.uri.toString());
      }
      editor.dispose();
      editorEvents.dispose();
      editorActions.dispose();
      typingsLoader.disposeAll();
      eslintManager.dispose();
    },
    editor,
    internalApi,
    externalApi,
  };
};
