import * as monaco from 'monaco-editor-core';
import _ from 'lodash';
import cx from 'classnames';
import React, { Component } from 'react';
import s from './HoverWidget.scss';
import TypeDetails from '../TypeDetails/TypeDetails';
import { HoverImage } from '../HoverImage/HoverImage';
import { LintErrors } from '../LintErrors/LintErrors';
import withExperiments from '../Experiments/withExperiments';
import { getMediaItemType } from '../../features/mediaManagerUtils';
import { WixCodeCodeContext } from '../context/WixCodeCodeContext';
import { getEntryDetails } from '../../monaco/utils/detailsUtils';
import { CSS_DEFAULT_LANGUAGE } from '../../monaco/constants/languageConstants';

function getStringLiteralAtPositionIfExist(model, position) {
  const hoverIndex = position.column - 1; // position.column starts at 1
  const lineContent = model.getLineContent(position.lineNumber);
  const stringLiteralQuotes = ["'", `"`, '`'];
  let openingQuotesData = null;
  let currentIndex = 0;
  let stringLiteral = null;

  const isEndOfLine = index => index >= lineContent.length;

  while (
    !isEndOfLine(currentIndex) &&
    (openingQuotesData || hoverIndex > currentIndex)
  ) {
    const currentChar = lineContent[currentIndex];

    if (stringLiteralQuotes.includes(currentChar)) {
      if (!openingQuotesData) {
        openingQuotesData = { position: currentIndex, char: currentChar };
      } else if (openingQuotesData.char === currentChar) {
        if (currentIndex >= hoverIndex) {
          stringLiteral = {
            value: lineContent.slice(
              openingQuotesData.position + 1, // skip the opening quote
              currentIndex,
            ),
            startColumn: openingQuotesData.position + 2,
            endColumn: currentIndex + 1,
            line: position.lineNumber,
          };
        }
        openingQuotesData = null;
      }
    }
    currentIndex++;
  }

  return stringLiteral;
}
class HoverContentWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typeDetails: null,
      markers: [],
      position: {},
      mediaDetails: null,
      isMouseIn: false,
    };

    this.ID = 'contentHoverWidget';

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.clearContent = this.clearContent.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.updateWidget = _.debounce(this.updateWidget.bind(this), 500);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.position !== this.state.position) {
      this.props.editor.layoutContentWidget(
        this.props.editor._contentWidgets[this.ID].widget,
      );
    }
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('blur', this.clearContent);
    this.props.editor.onMouseMove(this.onMouseMove.bind(this));
    this.props.editor.onMouseLeave(() => this.clearContent());
    this.props.editor.onKeyUp(() => this.clearContent());
    this.props.editor.addContentWidget({
      getDomNode: () => this.wrapper,
      getId: () => this.ID,
      getPosition: () => ({
        position: this.state.position,
        preference: [
          monaco.editor.ContentWidgetPositionPreference.ABOVE,
          monaco.editor.ContentWidgetPositionPreference.BELOW,
        ],
      }),
    });
  }

  componentWillUnmount() {
    this.updateWidget.cancel();
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('blur', this.clearContent);
  }

  async onMouseMove(e) {
    const lineNumber = _.get(e.target, 'position.lineNumber');
    const isAfterLines = _.get(e.target, 'detail.isAfterLines', false);
    const { isMouseIn } = this.state;
    if (isAfterLines || !lineNumber) {
      if (!isMouseIn) {
        this.clearContent();
      }
      return;
    }

    let typeDetails = null;
    let markers = [];
    const position = {
      lineNumber: e.target.position.lineNumber,
      column: e.target.position.column,
    };
    const editor = this.props.editor;
    const stringLiteral = getStringLiteralAtPositionIfExist(
      editor.getModel(),
      position,
    );
    const mediaItemType = getMediaItemType(_.get(stringLiteral, 'value'));
    const mediaDetails = mediaItemType
      ? { mediaItemType, ...stringLiteral }
      : null;
    const wordEntry = isAfterLines
      ? null
      : editor.getModel().getWordAtPosition(position);

    if (wordEntry) {
      position.column = wordEntry.startColumn;

      if (editor.getModel().getModeId() === 'javascript') {
        typeDetails = await getEntryDetails(
          editor.getModel(),
          position,
          wordEntry.word,
        );
      }
    }

    markers = isAfterLines
      ? []
      : monaco.editor.getModelMarkers({
          resource: editor.getModel().uri,
        });

    markers = markers.filter(marker =>
      // Return true if a position (position) is within a range made from two positions (marker)
      monaco.Range.containsPosition(marker, position),
    );

    if (typeDetails || markers.length > 0 || mediaDetails) {
      this.updateWidget(typeDetails, markers, position, mediaDetails);
    } else if (!this.state.isMouseIn) {
      this.clearContent();
    }
  }

  handleClickOutside(event) {
    if (this.wrapper && !this.wrapper.contains(event.target)) {
      this.clearContent();
    }
  }

  clearContent() {
    // Cancel the trailing debounced invocation for updateWidget
    this.updateWidget.cancel();
    this.setState({
      typeDetails: null,
      markers: [],
      position: {},
      mediaDetails: null,
      isMouseIn: false,
    });
  }

  updateWidget(typeDetails, markers, position, mediaDetails) {
    if (
      this.props.editor &&
      this.props.editor._contentWidgets &&
      this.props.editor._contentWidgets[this.ID]
    ) {
      this.setState({ typeDetails, markers, position, mediaDetails });
    }
  }

  onMouseEnter() {
    this.setState({ isMouseIn: true });
  }

  onMouseLeave() {
    this.setState({ isMouseIn: false });
  }

  containsErrors() {
    return this.state.markers.some(
      marker => marker.severity === monaco.MarkerSeverity.Error,
    );
  }

  containsWarnings() {
    return this.state.markers.some(
      marker => marker.severity === monaco.MarkerSeverity.Warning,
    );
  }
  containsInfo() {
    const model = this.props.editor.getModel();
    const isCSS =
      model &&
      !model.isDisposed() &&
      model.getModeId() === CSS_DEFAULT_LANGUAGE;
    return (
      isCSS &&
      this.state.markers.some(
        marker => marker.severity === monaco.MarkerSeverity.Info,
      )
    );
  }

  render() {
    const isContainsErrors = this.containsErrors();
    const isContainsWarning = this.containsWarnings();
    const isContainsInfo = this.containsInfo();

    const { typeDetails, mediaDetails } = this.state;
    let showTypeSignature = true;
    if (
      !_.get(this, 'state.typeDetails.name') ||
      !_.get(this, 'state.typeDetails.doc')
    ) {
      showTypeSignature = false;
    }

    const showTypeDetails =
      !isContainsWarning &&
      !isContainsErrors &&
      typeDetails &&
      showTypeSignature;

    const showLintErrors =
      isContainsErrors || isContainsWarning || isContainsInfo;

    const classes = cx(s.hoverWidget, s.withContent, s[this.context.theme], {
      [s.warningColor]: isContainsWarning,
      [s.errorColor]: isContainsErrors,
      [s.infoColor]: isContainsInfo,
    });

    return (
      <div
        ref={c => (this.wrapper = c)}
        className={classes}
        data-hook="hover-content-widget"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {showTypeDetails && (
          <TypeDetails
            type={typeDetails.type}
            name={typeDetails.name}
            doc={typeDetails.doc}
          />
        )}
        {showLintErrors && (
          <LintErrors
            markers={this.state.markers}
            dataHook="lint-error-marker"
            editor={this.props.editor}
          />
        )}
        {mediaDetails && (
          <HoverImage
            mediaManager={this.props.mediaManager}
            editor={this.props.editor}
            mediaDetails={mediaDetails}
          />
        )}
      </div>
    );
  }
}

HoverContentWidget.contextType = WixCodeCodeContext;

export default withExperiments(HoverContentWidget);
