import * as monaco from 'monaco-editor-core';
import internalModelsApi from './internalModelsApi';
import { modelIdToMonacoUri } from '../utils';
import searchApiCreator from './search';
import uuid from 'uuid/v4';
import extraTypingsServiceCreator from '../typings/extraTypingsService';

const FILE_MODELS_API_NAMESPACE = 'FILE_MODELS_API_NAMESPACE';

const namespacesModelsMap = {};

const convertModelIdToPath = path => path.replace('file:///', '');

const buildNamespacedModelsApi = ({ namespace }) => {
  namespacesModelsMap[namespace] = new Set();

  const isModelInNamespace = model =>
    namespacesModelsMap[namespace].has(model.id);

  const addModelToNamespace = model =>
    namespacesModelsMap[namespace].add(model.id);

  const removeModelFromNamespace = model =>
    namespacesModelsMap[namespace].delete(model.id);

  const getNamespaceModels = () =>
    monaco.editor.getModels().filter(isModelInNamespace);

  let suppressUserEventTrigger = false;
  const extraTypingsService = extraTypingsServiceCreator();

  const suppressUserEvent = fn => {
    suppressUserEventTrigger = true;
    fn();
    suppressUserEventTrigger = false;
  };

  const createUserEventCallback = callback => {
    return (...args) => {
      if (!suppressUserEventTrigger) {
        callback(...args);
      }
    };
  };

  const getAll = () => {
    return getNamespaceModels().map(model => ({
      getValue: () => model.getValue(),
      modelId: model.uri.toString(),
      dispose: () => model.dispose(),
    }));
  };

  const get = ({ modelId }) => {
    const model = monaco.editor.getModel(modelIdToMonacoUri(modelId));
    if (!model || !isModelInNamespace(model)) {
      return;
    }
    const { getValue } = model;

    return {
      getValue: getValue.bind(model),
    };
  };

  const create = ({ value, language, path = `${uuid()}.js` }) => {
    const model = monaco.editor.createModel(
      value,
      language, // will be chosen by the file's extension
      monaco.Uri.file(path),
    );
    addModelToNamespace(model);
    if (namespace === FILE_MODELS_API_NAMESPACE) {
      extraTypingsService.add({ path });
    }

    return {
      modelId: model.uri.toString(),
      onChange: cb =>
        model.onDidChangeContent(
          createUserEventCallback(() => cb(model.getValue())),
        ),
    };
  };

  const remove = ({ modelId }) => {
    const model = internalModelsApi.getModel({ modelId });
    if (!isModelInNamespace(model)) {
      throw new Error('Model not in namespace');
    }
    removeModelFromNamespace(model);
    model.dispose();
    if (namespace === FILE_MODELS_API_NAMESPACE) {
      const path = convertModelIdToPath(modelId);
      extraTypingsService.remove({ path });
    }
  };

  return {
    create,
    update: (...args) =>
      suppressUserEvent(() => internalModelsApi.update(...args)),
    remove,
    get,
    getAll,
    search: searchApiCreator(getNamespaceModels),
  };
};

export const namespacedModelsApi = namespace => {
  if (namespace === FILE_MODELS_API_NAMESPACE) {
    throw new Error(
      `Can't use reserved namespace Symbol('${FILE_MODELS_API_NAMESPACE.toString()}').`,
    );
  }
  if (namespace === '') {
    throw new Error(`Namespace not supplied`);
  }

  return buildNamespacedModelsApi({
    namespace,
  });
};

export const modelsApi = () => {
  return buildNamespacedModelsApi({
    namespace: FILE_MODELS_API_NAMESPACE,
  });
};

export const registerCustomCompletionItemProvider = (languageId, provider) =>
  monaco.languages.registerCompletionItemProvider(languageId, provider);

