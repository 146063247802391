import React, { Component } from 'react';
import * as monaco from 'monaco-editor-core';
import style from './HoverImage.scss';
import classNames from 'classnames';

import {
  resolveWixUri,
  getUrlFromMediaManagerPayload,
} from '../../features/mediaManagerUtils';
import BgVideoIndicator from '../../../assets/bgVideoIndicator.svg';
import { mediaTypes } from '@wix/cm-media-utils/lib/mediaItem';
import { getBiLogger } from '../../services/bi';
import { ideAutocompletePanelShow } from '@wix/bi-logger-platform/v2';

const resolveHoverTextByMediaType = mediaItemType =>
  `To change the ${mediaItemType}, enter a new URL\nor open the `;

export class HoverImage extends Component {
  openMediaManager() {
    const origin = 'hover_image';
    const { mediaItemType } = this.props.mediaDetails;
    switch (mediaItemType) {
      case mediaTypes.IMAGE:
        return this.props.mediaManager.openImageMediaManager({ origin });
      case mediaTypes.VECTOR:
        return this.props.mediaManager.openVectorMediaManager({ origin });
      case mediaTypes.VIDEO:
        return this.props.mediaManager.openVideoMediaManager({ origin });
      default:
        return null;
    }
  }

  async editMediaUrl() {
    const { mediaDetails } = this.props;
    const mediaManagerPayload = await this.openMediaManager();

    if (mediaManagerPayload) {
      const url = getUrlFromMediaManagerPayload(
        mediaDetails.mediaItemType,
        mediaManagerPayload,
      );
      this.props.editor.executeEdits('setContent', [
        {
          range: new monaco.Range(
            mediaDetails.line,
            mediaDetails.startColumn,
            mediaDetails.line,
            mediaDetails.endColumn,
          ),
          text: url,
        },
      ]);
    }
  }

  componentDidMount() {
    const { mediaDetails } = this.props;

    const biLogger = getBiLogger();
    biLogger.report(
      ideAutocompletePanelShow({
        character_count: mediaDetails.value.length,
        origin: 'hover',
        panel_type: 'hover_image',
        user_input: mediaDetails.value,
      }),
    );
  }

  render() {
    const { mediaDetails } = this.props;
    const imageUrl = resolveWixUri(mediaDetails.value, 400, 250);
    const isVideo = mediaDetails.mediaItemType === mediaTypes.VIDEO;
    const hoverText = resolveHoverTextByMediaType(mediaDetails.mediaItemType);
    classNames({
      [style.hoverImage]: true,
    });

    return (
      <div
        data-hook="root-hover-widget-image"
        className={style.hoverImageContainer}
      >
        <div>
          <img
            data-hook="hover-widget-image"
            alt=""
            className={style.hoverImage}
            src={imageUrl}
          />
          {isVideo && <BgVideoIndicator />}
        </div>
        <div className={style.hoverText}>
          {hoverText}
          <span
            data-hook="edit-media-manager"
            className={style.openMediaManager}
            onClick={() => this.editMediaUrl()}
          >
            Media Manager
          </span>
        </div>
      </div>
    );
  }
}
