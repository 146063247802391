import { Languages } from '../../monaco';
import * as monaco from 'monaco-editor-core';
import 'monaco-languages/release/esm/javascript/javascript.contribution';
import 'monaco-typescript/release/esm/monaco.contribution';
import './setupJsonLanguageDefaults';

const monacoTypescript = (monaco.languages as typeof Languages).typescript;

const defaultCompilerOptions = {
  noLib: true,
  allowJs: true,
  noEmit: false,
  checkJs: true,
  jsx: 'react',
  allowSyntheticDefaultImports: true,
  allowNonTsExtensions: true,
  resolveJsonModule: true,
  target: monacoTypescript.ScriptTarget.ES2020,
  moduleResolution: monacoTypescript.ModuleResolutionKind.NodeJs,
  baseUrl: '.',
  paths: {
    'public/*': ['file:///public/*'],
    'backend/*': ['file:///backend/*'],
  },
  forceConsistentCasingInFileNames: true
};

export function convertToMonacoBaseLib(lib: string): string {
  return lib.toLowerCase();
}

export function setCompilerLibs(libs: string[]): void {
  monacoTypescript.javascriptDefaults.setCompilerOptions({
    ...defaultCompilerOptions,
    lib: libs.map(convertToMonacoBaseLib),
    noLib: false,
  });
}

export function toggleValidation(shouldValidate: boolean): void {
  const currentDiagnosticOptions =
    monacoTypescript.javascriptDefaults.getDiagnosticsOptions();
  const currentlyValidating = !currentDiagnosticOptions.noSemanticValidation;
  if (shouldValidate !== currentlyValidating) {
    monacoTypescript.javascriptDefaults.setDiagnosticsOptions({
      ...currentDiagnosticOptions,
      noSemanticValidation: !shouldValidate,
      noSyntaxValidation: !shouldValidate,
      onlyVisible: shouldValidate,
    });
  }
}

const initializeMonacoJavascriptDefaults = () => {
  monacoTypescript.javascriptDefaults.setCompilerOptions(
    defaultCompilerOptions,
  );
  monacoTypescript.javascriptDefaults.setEagerModelSync(true);
  monacoTypescript.javascriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: false,
    noSyntaxValidation: false,
    onlyVisible: true,
  });
};

initializeMonacoJavascriptDefaults();
