import { languages } from 'monaco-editor-core';

export const Origins = {
  LOCAL: 'local',
  RECENTLY_USED_IMAGES: 'Recently Used Images',
  WIXSDK: 'wixsdk',
  WIX_APIS: 'Wix APIs',
  ECMASCRIPT: 'ecmascript',
  TEMPLATES: 'Templates',
  KEYWORDS: 'Keywords',
};

export const CompletionKind = languages.CompletionItemKind;
export const CompletionItemInsertTextRule =
  languages.CompletionItemInsertTextRule;
