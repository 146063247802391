import { CommonFindController } from 'monaco-editor-core/esm/vs/editor/contrib/find/findController';
import { FIND_IDS } from 'monaco-editor-core/esm/vs/editor/contrib/find/findModel';
import { getBiLogger } from '../../../services/bi';
import { ideSearchAllFilesSearch } from '@wix/bi-logger-platform/v2';
import _ from 'lodash';

const onFirstTimeWidgetOpen = (findState, cb) => {
  const oneTimeEvent = findState.onFindReplaceStateChange(changeEvent => {
    if (changeEvent.isRevealed && findState.isRevealed) {
      oneTimeEvent.dispose();
      cb();
    }
  });
};

const alwaysShowReplaceUI = findState => {
  findState.onFindReplaceStateChange(changeEvent => {
    if (
      changeEvent.isRevealed &&
      findState.isRevealed &&
      !findState.isReplaceRevealed
    ) {
      findState.change({ isReplaceRevealed: true });
    }
  });
};

const notifyOnToggle = (editor, findState, onFindReplaceToggle) => {
  findState.onFindReplaceStateChange(changeEvent => {
    if (changeEvent.isRevealed) {
      onFindReplaceToggle({ isOpen: findState.isRevealed });
    }
  });

  editor.onDidDispose(() => onFindReplaceToggle({ isOpen: false }));
};

const addAutomationIDsToElements = (findState, findController) => {
  const setAid = (el, aid) => el.setAttribute('data-aid', aid);

  onFirstTimeWidgetOpen(findState, () => {
    const findWidget = findController._widget;
    setAid(findWidget._domNode, 'find-panel');
    setAid(findWidget._findInput.inputBox.inputElement, 'find-input');
    setAid(findWidget._replaceInput.inputBox.inputElement, 'replace-input');
    setAid(findWidget._prevBtn._domNode, 'previous-button');
    setAid(findWidget._nextBtn._domNode, 'next-button');
    setAid(findWidget._replaceBtn._domNode, 'replace-button');
    setAid(findWidget._replaceAllBtn._domNode, 'replaceAll-button');
    setAid(findWidget._closeBtn._domNode, 'close-button');
  });
};

const onStateChange = findController => {
  const state = findController.getState();
  const { searchString, matchCase, isRegex, wholeWord, matchesCount } = state;
  const biLogger = getBiLogger();
  if (searchString) {
    biLogger.report(
      ideSearchAllFilesSearch({
        input_name: searchString,
        is_case: matchCase,
        is_reg_exp: isRegex,
        is_whole_word: wholeWord,
        num_of_results: matchesCount,
        origin: 'IDE',
      }),
    );
  }
};

export default ({ editor, onFindReplaceToggle = _.noop }) => {
  const findController = editor.getContribution(CommonFindController.ID);
  const findState = findController.getState();

  alwaysShowReplaceUI(findState);
  notifyOnToggle(editor, findState, onFindReplaceToggle);
  addAutomationIDsToElements(findState, findController);
  findState.onFindReplaceStateChange(() => onStateChange(findController));

  const api = {
    openFindReplace: () => {
      editor.trigger('find-replace-api', FIND_IDS.StartFindAction);
    },
  };

  return api;
};
