export default ({ editor }) => {
  let suppressUserEventTrigger = false;

  const createUserEventCallback = callback => {
    return (...args) => {
      if (!suppressUserEventTrigger) {
        callback(...args);
      }
    };
  };

  const suppressUserEvents = fn => {
    suppressUserEventTrigger = true;
    fn();
    suppressUserEventTrigger = false;
  };

  return {
    suppressUserEvents,
    onSelection: cb =>
      editor.onDidChangeCursorSelection(
        createUserEventCallback(event => {
          const { startLineNumber, startColumn, endLineNumber, endColumn } =
            event.selection;
          cb({
            start: { line: startLineNumber, column: startColumn },
            end: { line: endLineNumber, column: endColumn },
          });
        }),
      ),
    onFocus: cb => editor.onDidFocusEditorWidget(cb),
    onBlur: cb => editor.onDidBlurEditorWidget(cb),
    onLinkNavigated: cb => {
      const linkDetector = editor.getContribution('editor.linkDetector');
      const _openLink = linkDetector.openLinkOccurrence;
      linkDetector.openLinkOccurrence = (occurence, ...linkArgs) => {
        Promise.resolve(cb(occurence.link.url)).then(() => {
          _openLink.apply(linkDetector, [occurence, ...linkArgs]);
        });
      };
    },
  };
};
