import { FileContext as FileContextType } from '@wix/wix-code-common';
import {
  ContextDeclarationService,
  GetFileContextData,
} from '@wix/wix-code-editor-types';
import {
  FileContextObject,
  FileContextManager,
  FileContextManagerParams,
  ExtraLib,
} from '../../../main';
import _ from 'lodash';
import { setCompilerLibs } from '../../../monaco/patches/languages/setupTypescriptLanguageDefaults';
import * as monaco from 'monaco-editor-core';
import { Languages } from '../../../monaco/monaco';
import wixCodeEditorTypesService from './wixCodeEditorTypesService';

export default ({
  typingsLoader,
  getContext,
}: FileContextManagerParams): FileContextManager => {
  let prevContext = getContext();
  let contextDeclarations: ContextDeclarationService;
  let isTypeScriptWorkerInitialized = false;
  let isBaseLibsLoaded = false;

  const setContextDeclarations = (
    givenDeclarations: ContextDeclarationService,
  ) => {
    contextDeclarations = givenDeclarations;
  };

  const shouldSetCompilerLibs = (
    currentLibs: string[],
    newLibs: string[],
  ): boolean => {
    const didBaseLibsChange =
      currentLibs.some(lib => !newLibs.includes(lib)) ||
      newLibs.some(lib => !currentLibs?.includes(lib));
    return !isBaseLibsLoaded || didBaseLibsChange;
  };

  const getBaseLib = (context: FileContextType): string[] =>
    contextDeclarations.getBaseLib(context);

  const updateBaseLibsIfNecessary = () => {
    if (!isTypeScriptWorkerInitialized) {
      return;
    }

    const newContextType = getContext().type;
    const newLibs = getBaseLib(newContextType);
    const currentLibs = getBaseLib(prevContext.type);
    if (shouldSetCompilerLibs(currentLibs, newLibs)) {
      setCompilerLibs(getBaseLib(newContextType));
      isBaseLibsLoaded = true;
    }
  };

  const hasTypesLoaded = (): boolean => !!contextDeclarations;

  const getCurrentContextExtraLibs = (): Promise<ExtraLib[]> => {
    const fileContext: FileContextObject = getContext();
    const contextType = fileContext.type;

    return contextDeclarations.getDeclarations(
      contextType,
      fileContext as GetFileContextData<typeof contextType>,
    );
  };

  const forceDisposeAndLoadNewFileContext = async () => {
    if (!hasTypesLoaded()) {
      return;
    }
    const fileContext: FileContextObject = getContext();
    const extraLibs: ExtraLib[] = await getCurrentContextExtraLibs();

    typingsLoader.loadContextTypings(extraLibs);
    updateBaseLibsIfNecessary();
    wixCodeEditorTypesService.wixModules.setModulesCompletions(
      fileContext.type,
    );

    prevContext = fileContext;
  };

  const disposeAndLoadIfFileContextChanged = () => {
    if (!hasTypesLoaded()) {
      return;
    }
    // in the future we may get to a situation where the fileContext hasn't changed
    // but some of the background models have changed and extraLibs reload is needed.
    // to fix it we can add a condition to the next if statement that checks if one of the model's paths/ content has been changed (simple checksum).
    // i choose not to implement it at this point because adding this condition may cause performance degradation in large sites
    // plus i didn't find a real-life situation when a user can recreate this scenario.
    const currentContext = getContext();
    if (!_.isEqual(prevContext, currentContext)) {
      forceDisposeAndLoadNewFileContext();
    }
  };

  // need to wait for javascript worker before setting new compiler options
  const waitForWorkerAndLoadFileContext = async (): Promise<void> => {
    const worker = await (
      monaco.languages as typeof Languages
    ).typescript.getJavaScriptWorker();
    await worker();

    isTypeScriptWorkerInitialized = true;
    forceDisposeAndLoadNewFileContext();
  };

  return {
    setContextDeclarations,
    forceDisposeAndLoadNewFileContext,
    disposeAndLoadIfFileContextChanged,
    waitForWorkerAndLoadFileContext,
  };
};
