const ExtendableError = require('es6-error').default || require('es6-error')

const ERROR_NAME = 'UserError'

class UserError extends ExtendableError {
  constructor(message, error) {
    super(message)
    this.name = ERROR_NAME
    this.originalError = error
  }
}

module.exports = UserError
