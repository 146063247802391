import React, { Component } from 'react';
import _ from 'lodash';
import * as monaco from 'monaco-editor-core';
import cx from 'classnames';
import s from './TypeSignature.scss';
import { WixCodeCodeContext } from '../context/WixCodeCodeContext';

const getCompletionIcon = type => {
  const key = _.findKey(
    monaco.languages.CompletionItemKind,
    val => val === type,
  );
  return key ? key.toLowerCase() : 'property';
};

const renderReturnType = (returnType, theme) => {
  return (
    <span>
      :&nbsp;
      <span className={cx(s.returnType, s[theme])}>{returnType}</span>
    </span>
  );
};

class TypeSignature extends Component {
  render() {
    const { type, name, returnType } = this.props;
    const icon = getCompletionIcon(type);

    return (
      <div
        className={cx(s.typeSignature, s[this.context.theme], {
          [s.focus]: this.props.focus,
        })}
        style={{ display: 'flex' }}
      >
        <span className={cx({ [s.icon]: true, [s[icon]]: true })} />
        <span className={cx(s[this.context.theme], s.functionName)}>
          {name}
          {returnType ? renderReturnType(returnType, this.context.theme) : null}
        </span>
      </div>
    );
  }
}

TypeSignature.contextType = WixCodeCodeContext;

export default TypeSignature;
