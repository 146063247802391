import { Languages } from '../../monaco/monaco';
import * as monaco from 'monaco-editor-core';
import _ from 'lodash';
import extraTypingsServiceCreator from './extraTypingsService';
import { TypingsLoader, ExtraLib } from '../../main';

const loadJsLibs = (
  jsLibs: ExtraLib[],
): { [fileName: string]: monaco.IDisposable } =>
  jsLibs.reduce(
    (map, jsLib) =>
      Object.assign(map, {
        [jsLib.path]: (
          monaco.languages as typeof Languages
        ).typescript.javascriptDefaults.addExtraLib(jsLib.content, jsLib.path),
      }),
    {},
  );

const getExtraLibs = () =>
  (
    monaco.languages as typeof Languages
  ).typescript.javascriptDefaults.getExtraLibs();

export const typingsLoaderCreator = (): TypingsLoader.TypingsLoader => {
  const loadedLibsMap: { [fileName: string]: monaco.IDisposable } = {};
  const extraDefinitions: { [fileName: string]: boolean } = {};
  const extraTypingsService = extraTypingsServiceCreator();

  const notAnExtraDefinition = (lib: string) => !extraDefinitions[lib];

  const disposeAll = () => {
    return Object.keys(loadedLibsMap)
      .filter(notAnExtraDefinition)
      .forEach(lib => (loadedLibsMap[lib] as monaco.IDisposable).dispose());
  };

  const loadContextTypings = (newContextLibs: ExtraLib[]): void => {
    newContextLibs.push(...extraTypingsService.getDeclarations());
    const currentLibsMap = Object.keys(getExtraLibs()).filter(
      notAnExtraDefinition,
    );
    // todo:: check if the code does as expected and remove 'any'
    // TL;DR _.keyBy is returning {[index: string]: ExtraLib;} and _.difference expect an Array
    const newContextLibsKeyBy: string[] = _.keyBy(
      newContextLibs,
      (lib: ExtraLib) => lib.path,
    ) as any;
    const libsToDispose = _.difference(currentLibsMap, newContextLibsKeyBy);

    libsToDispose.forEach(libPath => {
      if (loadedLibsMap[libPath]) {
        (loadedLibsMap[libPath] as monaco.IDisposable).dispose();
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete loadedLibsMap[libPath];
      }
    });

    Object.assign(loadedLibsMap, loadJsLibs(newContextLibs));
  };

  const removeDefinition = ({
    fileName,
  }: TypingsLoader.RemoveDefinitionParams) => {
    if (!loadedLibsMap[fileName]) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete extraDefinitions[fileName];
    return (loadedLibsMap[fileName] as monaco.IDisposable).dispose();
  };

  const addDefinition = ({
    fileName,
    content,
  }: TypingsLoader.AddDefinitionParams) => {
    if (loadedLibsMap[fileName]) {
      removeDefinition({ fileName });
    }

    extraDefinitions[fileName] = true;
    Object.assign(loadedLibsMap, loadJsLibs([{ path: fileName, content }]));
  };

  return {
    disposeAll,
    loadContextTypings,
    addDefinition,
    removeDefinition,
  };
};
