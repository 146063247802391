import * as monaco from 'monaco-editor-core';
import _ from 'lodash';
import { DEFAULT_LANGUAGE } from '../../../monaco/constants/languageConstants';
import {
  RegisterEditorEvents,
  RegisterEditorEventsParams,
} from '../../../main';
import { getBiLogger } from '../../../services/bi';
import { ideContextMenuOpen } from '@wix/bi-logger-platform/v2';
import { highlightingComponentsServiceCreator } from './highlightingComponents';
import { experimentNames } from '../../../constants/experiments';
import { completionsEventsCreator } from '../../../monaco/monacoCompletions/completionsEvents';

const clearModelsMarkers = (modelsUrls: (monaco.Uri | null)[]): void =>
  modelsUrls.filter(Boolean).forEach(modelUrl => {
    const model = monaco.editor.getModel(modelUrl as monaco.Uri);
    if (!model) {
      return;
    }
    const language = _.get(
      model,
      '_languageIdentifier.language',
      DEFAULT_LANGUAGE,
    );
    monaco.editor.setModelMarkers(model, language, []);
  });

export default ({
  editor,
  fileContextManager,
  eslintManager,
  cssLintManager,
  selectComponentByNickname,
  deselectComponents,
  experiments,
}: RegisterEditorEventsParams): RegisterEditorEvents => {
  const initAddTopPadding = (): (() => void) => {
    const viewZone = {
      afterLineNumber: 0,
      heightInPx: 6,
      domNode: document.createElement('div'),
    };
    const addViewZone = () =>
      editor.changeViewZones(accessor => accessor.addZone(viewZone));
    addViewZone();
    return addViewZone;
  };

  const refreshManagersModelMarkers = () => {
    eslintManager.refreshModelMarkers();
    cssLintManager?.refreshModelMarkers();
  }

  const addTopPadding = initAddTopPadding();
  const editorEvents: monaco.IDisposable[] = [];
  editorEvents.push(
    editor.onDidFocusEditorWidget(() => {
      fileContextManager.disposeAndLoadIfFileContextChanged();
      refreshManagersModelMarkers();
    }),
  );

  editorEvents.push(
    editor.onDidChangeModel(
      async ({
        oldModelUrl,
        newModelUrl,
      }: monaco.editor.IModelChangedEvent) => {
        clearModelsMarkers([oldModelUrl, newModelUrl]);

        await fileContextManager.forceDisposeAndLoadNewFileContext();
        refreshManagersModelMarkers();
        addTopPadding();
      },
    ),
  );

  editorEvents.push(
    editor.onDidChangeModelContent(() => {
      refreshManagersModelMarkers();
    }),
  );

  editorEvents.push(
    editor.onContextMenu(async () => {
      const biLogger = getBiLogger();
      await biLogger.report(ideContextMenuOpen({ origin: 'right_click' }));
    }),
  );

  if (experiments.isOpen(experimentNames.HIGHLIGHT_COMPONENTS)) {
    const highlightingComponentService = highlightingComponentsServiceCreator({
      selectComponentByNickname,
      deselectComponents,
    });

    editorEvents.push(
      editor.onDidChangeCursorPosition(
        highlightingComponentService.handleCursorSelectionEvent,
      ),
    );
  }

  const completionEvents = completionsEventsCreator({ editor });
  editorEvents.push(completionEvents.registerPanelFocusEvent());

  return {
    dispose: () => editorEvents.forEach(e => e.dispose()),
  };
};
