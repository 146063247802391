import * as monaco from 'monaco-editor-core';
import { DEFAULT_LANGUAGE } from '../../constants/languageConstants';
import {
  CompletionsFunc,
  completionsManager,
} from '../../monacoCompletions/completionsManager';

interface ProvideCompletionItemsPatchParams {
  provider: monaco.languages.CompletionItemProvider;
  completionsFunc: CompletionsFunc;
  languageId: string;
}

const provideCompletionItemsPatch = ({
  provider,
  completionsFunc,
  languageId,
}: ProvideCompletionItemsPatchParams) => {
  if (languageId !== DEFAULT_LANGUAGE) {
    return;
  }

  const _provideCompletionsItems = provider.provideCompletionItems;
  provider.provideCompletionItems = async (model, position, ...args) => {
    const completionsList: monaco.languages.CompletionList =
      (await _provideCompletionsItems.apply(provider, [
        model,
        position,
        ...args,
      ])) || { suggestions: [] };

    completionsList.suggestions = completionsFunc({
      completions: completionsList.suggestions,
      model,
      position,
    }) as unknown as monaco.languages.CompletionItem[];

    return completionsList;
  };
};

const registerCompletionItemProviderPatch = (
  completionsFunc: CompletionsFunc,
) => {
  const _registerCompletionItemProvider =
    monaco.languages.registerCompletionItemProvider;

  monaco.languages.registerCompletionItemProvider = (languageId, provider) => {
    provideCompletionItemsPatch({
      provider,
      completionsFunc,
      languageId,
    });

    return _registerCompletionItemProvider(languageId, provider);
  };
};

const applyRegisterCompletionItemProviderPatch = () => {
  registerCompletionItemProviderPatch(completionsManager.patchCompletions);
};

applyRegisterCompletionItemProviderPatch();
