(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "react"], factory);
	else if(typeof exports === 'object')
		exports["codeEditor"] = factory(require("lodash"), require("react"));
	else
		root["codeEditor"] = factory(root["lodash"], root["react"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__467__, __WEBPACK_EXTERNAL_MODULE__8156__) => {
return 