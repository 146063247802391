import _ from 'lodash';

const symbol = Symbol('err_handled');

export default captureException => _module => {
  const handler = {
    get(target, propKey) {
      const item = target[propKey];
      if (_.isFunction(item)) {
        return (...args) => {
          try {
            return item.apply(target, args);
          } catch (err) {
            if (!err[symbol]) {
              captureException(err);
            }

            // avoid multiple capturing for the same error
            // by marking caught exceptions as 'handled'
            throw Object.assign(err, { [symbol]: true });
          }
        };
      }

      return item;
    },
  };
  return new Proxy(_module, handler);
};
