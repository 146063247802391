import * as monaco from 'monaco-editor-core';
import constants from '../constants';
import { getBiLogger } from '../../../services/bi';
import { ideToolbarAction } from '@wix/bi-logger-platform/v2';
import { MenuRegistry } from 'monaco-editor-core/esm/vs/platform/actions/common/actions';

export default ({ editor, externalApi, onSeeAllShortcuts }) => {
  MenuRegistry._menuItems.clear();
  let disposableEditorActions = [];

  const editorActions = [
    {
      id: 'find',
      label: 'Find',
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_F],
      contextMenuGroupId: constants.CONTEXT_MENU.GROUP.NAVIGATION,
      contextMenuOrder: 0.2,
      async run() {
        const biLogger = getBiLogger();
        await biLogger.report(
          ideToolbarAction({
            item_name: 'search',
          }),
        );
        externalApi.openFindReplace();
      },
    },
    {
      id: 'seeAllShortcuts',
      label: 'See All Shortcuts',
      contextMenuGroupId: constants.CONTEXT_MENU.GROUP.MODIFICATION,
      contextMenuOrder: 0,
      async run() {
        const biLogger = getBiLogger();
        await biLogger.report(
          ideToolbarAction({
            item_name: 'shortcuts',
          }),
        );
        onSeeAllShortcuts();
      },
    },
  ];
  const codeEditingEditorActions = [
    {
      id: 'format',
      label: 'Format',
      keybindings: [
        monaco.KeyMod.Alt | monaco.KeyMod.Shift | monaco.KeyCode.KEY_F,
      ],
      contextMenuGroupId: constants.CONTEXT_MENU.GROUP.NAVIGATION,
      contextMenuOrder: 0.1,
      async run() {
        const biLogger = getBiLogger();
        await biLogger.report(
          ideToolbarAction({
            item_name: 'format',
          }),
        );
        externalApi.format();
      },
    },
    {
      id: 'undo',
      label: 'Undo',
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_Z],
      contextMenuGroupId: constants.CONTEXT_MENU.GROUP.NAVIGATION,
      contextMenuOrder: 0.3,
      async run() {
        const biLogger = getBiLogger();
        await biLogger.report(
          ideToolbarAction({
            item_name: 'undo',
          }),
        );
        externalApi.undo();
      },
    },
    {
      id: 'redo',
      label: 'Redo',
      keybindings: [
        monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KEY_Z,
      ],
      contextMenuGroupId: constants.CONTEXT_MENU.GROUP.NAVIGATION,
      contextMenuOrder: 0.4,
      async run() {
        const biLogger = getBiLogger();
        await biLogger.report(
          ideToolbarAction({
            item_name: 'redo',
          }),
        );
        externalApi.redo();
      },
    },
  ];

  const dispose = () => {
    disposableEditorActions.forEach(actionToDispose =>
      actionToDispose.dispose(),
    );
  };

  const update = readOnly_ => {
    dispose();
    disposableEditorActions = editorActions.map(editor.addAction.bind(editor));

    if (!readOnly_) {
      disposableEditorActions.push(
        ...codeEditingEditorActions.map(editor.addAction.bind(editor)),
      );
    }
  };

  return {
    update,
    dispose,
  };
};
