import React, { FC, useContext } from 'react';
import { WixCodeTheme } from '../../constants/wixCodeTheme';

type WixCodeCodeContextValue = {
  theme: WixCodeTheme;
};

const DEFAULT_VALUE: WixCodeCodeContextValue = { theme: WixCodeTheme.LIGHT };
export const WixCodeCodeContext =
  React.createContext<WixCodeCodeContextValue>(DEFAULT_VALUE);

export const WixCodeCodeContextProvider: FC<WixCodeCodeContextValue> = ({
  children,
  ...props
}) => {
  return (
    <WixCodeCodeContext.Provider value={props}>
      {children}
    </WixCodeCodeContext.Provider>
  );
};

export const useWixCodeCodeContext = (): WixCodeCodeContextValue => {
  return useContext(WixCodeCodeContext);
};
