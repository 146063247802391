import * as monaco from 'monaco-editor-core';
import { js_beautify as beautify } from 'js-beautify';
import { CSS_DEFAULT_LANGUAGE } from '../monaco/constants/languageConstants';

type IStandaloneCodeEditor = monaco.editor.IStandaloneCodeEditor;

const beautifyOptions = {
  indent_size: 4,
  indent_char: ' ',
  indent_with_tabs: false,
  jslint_happy: true,
  indent_inner_html: true,
  max_preserve_newlines: 2,
  preserve_newlines: true,
  good_stuff: true,
  selector_separator_newline: true,
  brace_style: 'collapse-preserve-inline',
  e4x: true,
};

const formatCode = (
  language: string,
  sourceText: string,
  options: Record<string, string | number | boolean>,
) => {
  switch (language) {
    case CSS_DEFAULT_LANGUAGE:
      // TODO: add beautifier for CSS
      return sourceText;
    default:
      return beautify(sourceText, options);
  }
};

export default (editor: IStandaloneCodeEditor) => {
  const allContent = editor.getValue();
  const model = editor.getModel();
  if (!model) {
    return;
  }
  const modelLang = model.getModeId();
  const selection = editor.getSelection();
  const selectedText = model.getValueInRange(selection!) || allContent;
  const newText = formatCode(modelLang, selectedText, beautifyOptions);

  if (selectedText !== allContent) {
    editor.executeEdits('format', [{ range: selection!, text: newText }]);
  } else {
    editor.executeEdits('format', [
      {
        range: new monaco.Range(1, 1, model.getLineCount() + 1, 1),
        text: newText,
      },
    ]);
  }
};
