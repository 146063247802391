import ts from 'typescript';
import { Possible } from '../../main';
import { CompletionKind } from '../constants/enums';

export const displayPartsToString = (
  displayParts?: ts.SymbolDisplayPart[],
): Possible<string> => {
  if (!displayParts) {
    return;
  }
  return displayParts.map(displayPart => displayPart.text).join('');
};

export const resolveMonacoCompletionItemKindFromTsKind = (tsKind: string) => {
  switch (tsKind) {
    case 'function':
      return CompletionKind.Function;
    case 'const':
      return CompletionKind.Constant;
    default:
      return '';
  }
};
