import React, { Component, Fragment } from 'react';
import * as monaco from 'monaco-editor-core';
import cx from 'classnames';
import initializeEditor from './initializeEditor';
import _ from 'lodash';
import s from './Editor.scss';
import HoverContentWidget from '../HoverWidgets/HoverContentWidget';
import { getBiLogger } from '../../services/bi';
import {
  ideFocusedOrBlurred,
  idePasteInjectedCode,
} from '@wix/bi-logger-platform/v2';
import withExperiments from '../Experiments/withExperiments';
import themeService from './services/themeService/theme';
import { WixCodeCodeContext } from '../context/WixCodeCodeContext';
import {
  AnyFixMe,
  CodeEditorExternalAPI,
  CodeEditorManager,
  ExperimentsObj,
  FileContextObject,
  Possible,
  TypingsLoader,
} from '../../main';
import { WixCodeTheme } from '../../constants/wixCodeTheme';

interface CodeEditorProps {
  modelId: string;
  hideErrors: boolean;
  readOnly: boolean;
  onFindReplaceToggle: () => void;
  onFindInAllFiles: () => void;
  onSeeAllShortcuts: () => void;
  baseUrl: string;
  onFocus: () => void;
  onBlur: () => void;
  onSelection: () => void;
  onMount: (editorExternalAPI: CodeEditorExternalAPI.API) => void;
  theme: WixCodeTheme;
  selectComponentByNickname: (id: string) => Promise<void>;
  deselectComponents: () => Promise<void>;
  experiments: ExperimentsObj;
  selection: monaco.Selection;
  mediaManager: AnyFixMe;
  onCompletionsReady: () => void;
  typingsLoader: TypingsLoader.TypingsLoader;
  getFileContext: () => FileContextObject;
}

interface CodeEditorState {
  editor: Possible<monaco.editor.IStandaloneCodeEditor>;
  focusTime: Possible<number>;
}
class Editor extends Component<CodeEditorProps, CodeEditorState> {
  private editorApi: Possible<CodeEditorManager.CodeEditorManager>;
  private disposeEditor: Possible<() => void>;
  private element: Possible<HTMLDivElement>;

  constructor(props: CodeEditorProps) {
    super(props);

    this.editorApi = null;
    this.disposeEditor = null;

    this.state = {
      editor: null,
      focusTime: null,
    };
  }

  static defaultProps = {
    onSelection: _.noop,
    onFocus: _.noop,
    onBlur: _.noop,
    onCompletionsReady: _.noop,
    hideErrors: false,
    onFindInAllFiles: _.noop,
    readOnly: false,
  };

  sendPasteBi(pasteEvent: monaco.editor.IPasteEvent) {
    const { editor } = this.state;
    const model = editor?.getModel();
    if (!editor || !model) {
      return;
    }

    const { range } = pasteEvent;
    const pastedValue = model.getValueInRange(range);
    const valueLength = model.getValueLengthInRange(range);
    const filePath = model.uri.path;

    const biLogger = getBiLogger();
    biLogger.report(
      idePasteInjectedCode({
        action: 'paste',
        character_count: valueLength,
        code: pastedValue,
        file_path: filePath,
      }),
    );
  }

  sendFocusBi() {
    const biLogger = getBiLogger();
    biLogger.report(
      ideFocusedOrBlurred({
        state: 'focus_in',
      }),
    );
    this.setState({ focusTime: Date.now() });
  }

  sendBlurBi() {
    const focusTime = this.state.focusTime;
    const timeInFocus = focusTime ? Date.now() - focusTime : 0;

    const biLogger = getBiLogger();
    biLogger.report(
      ideFocusedOrBlurred({
        state: 'focus_out',
        time_in_focus: timeInFocus,
      }),
    );
    this.setState({ focusTime: null });
  }

  getContext() {
    return this.props.getFileContext() || { type: 'public' };
  }

  override componentDidMount() {
    const { editor, internalApi, externalApi, dispose } = initializeEditor({
      modelId: this.props.modelId,
      element: this.element as HTMLDivElement,
      onFindReplaceToggle: this.props.onFindReplaceToggle,
      onFindInAllFiles: this.props.onFindInAllFiles,
      onSeeAllShortcuts: this.props.onSeeAllShortcuts,
      experiments: this.props.experiments,
      baseUrl: this.props.baseUrl,
      getContext: this.getContext.bind(this),
      librariesLoadedCb: () => this.props.onCompletionsReady(),
      typingsLoader: this.props.typingsLoader,
      theme: this.context.theme,
      selectComponentByNickname: this.props.selectComponentByNickname,
      deselectComponents: this.props.deselectComponents,
      mediaManager: this.props.mediaManager,
    });

    this.editorApi = internalApi;
    this.disposeEditor = dispose;

    this.editorApi.onSelection(this.props.onSelection);
    this.editorApi.onFocus(this.props.onFocus);
    this.editorApi.onBlur(this.props.onBlur);

    this.setState({ editor });

    this.props.onMount(externalApi);

    this.editorApi.onFocus(this.sendFocusBi.bind(this));
    this.editorApi.onBlur(this.sendBlurBi.bind(this));
    editor.onDidPaste(this.sendPasteBi.bind(this));
  }

  override componentDidUpdate(prevProps: CodeEditorProps) {
    if (prevProps.theme !== this.props.theme) {
      themeService.setEditorTheme({
        theme: this.context.theme,
      });
    }

    this.editorApi?.updateState({
      oldModelId: prevProps.modelId,
      newModelId: this.props.modelId,
      selection: this.props.selection,
      hideErrors: this.props.hideErrors,
      readOnly: this.props.readOnly,
    });
  }

  override componentWillUnmount() {
    this.disposeEditor && this.disposeEditor();
  }

  override render() {
    const { editor } = this.state;

    return (
      <div
        className={s.editorWrapper}
        data-hook="code-editor-container"
        data-is-read-only={(!!this.props.readOnly).toString()}
      >
        <div
          className={cx(s.monacoWrapper, s[this.context.theme])}
          key="monaco"
          ref={c => (this.element = c)}
        />
        {editor && (
          <Fragment>
            <div key="hover-widgets">
              <HoverContentWidget
                key="hover-content-widget"
                editor={editor}
                mediaManager={this.props.mediaManager}
              />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

Editor.contextType = WixCodeCodeContext;

export default withExperiments(Editor);
