import * as monaco from 'monaco-editor-core';
import filters from '../markerFilters';
import { DEFAULT_LANGUAGE } from '../../constants/languageConstants';

const originalSetMarkers = monaco.editor.setModelMarkers;

monaco.editor.setModelMarkers = function patchSetModelMarkers(
  model,
  owner,
  markers,
) {
  if (owner !== DEFAULT_LANGUAGE) {
    return originalSetMarkers(model, owner, markers);
  }
  let filteredMarkers: monaco.editor.IMarkerData[] = [];
  try {
    filteredMarkers = markers.filter(marker =>
      filters.every(filter => filter({ model, owner, marker })),
    );
  } catch (e) {
    // falling silently
    // eslint-disable-next-line no-console
    console.error(e);
  }
  return originalSetMarkers(model, owner, filteredMarkers);
};
