import * as monaco from 'monaco-editor-core';
import {
  displayPartsToString,
  resolveMonacoCompletionItemKindFromTsKind,
} from './monacoUtils';

const getQuickInfoDetails = quickInfo => {
  const { documentation, displayParts } = quickInfo;
  if (documentation && documentation.length > 0) {
    return displayPartsToString(documentation);
  }
  if (displayParts) {
    return displayPartsToString(displayParts);
  }

  return '';
};

export const getEntryDetails = async (model, position, entry) => {
  const worker = await monaco.languages.typescript.getJavaScriptWorker();
  const resource = model.uri.toString();
  const offset = model.getOffsetAt(position);
  const client = await worker(resource);
  const quickInfo = await client.getQuickInfoAtPosition(resource, offset);

  if (quickInfo) {
    const details = getQuickInfoDetails(quickInfo);

    return {
      name: entry,
      type: resolveMonacoCompletionItemKindFromTsKind(quickInfo.kind),
      doc: details,
    };
  }
};
