import {
  codeExportsService as _codeExportsService,
  UserError as userError,
} from '@wix/wix-code-parser';
import {
  decorationTypes as _decorationTypes,
  typingsLoaderCreator,
  definitionsApiCreator,
  namespacedModelsApi as _namespacedModelsApiCreator,
  modelsApi as _modelsApiCreator,
  registerCustomCompletionItemProvider,
} from './api';
import _codeEditorCreator from './components/Root/Root';
import { initSentry } from './services/monitoring/sentryClient';
import { initBiLogger, setBiContext } from './services/bi';
import { wrapModule, wrapComponent } from './services/monitoring';
import setupEnvironment from './monaco/worker/setupEnvironment';
import dynamicImportPolyfill from 'dynamic-import-polyfill';
import { AnyFixMe } from './main';
import { KeyBindings } from './components/Editor/services/codeEditorExternalApi';
import * as monaco from 'monaco-editor-core';
import { registerCustomCodeValidator } from './api/validators/customCodeValidators';
import * as languageConsts from './monaco/constants/languageConstants';

export { WixCodeCustomCompletionItem } from './monaco/monacoCompletions/completionsManager';

// This needs to be done before any dynamic imports are used.
dynamicImportPolyfill.initialize();

setupEnvironment();

const sentryClient = initSentry({
  dsn: 'https://8bfff525eb91440596dd5f822b7bc8d8@sentry.wixpress.com/158',
});

const typingsLoader = typingsLoaderCreator();
initBiLogger();

const wrapComponentWithSentry = wrapComponent(sentryClient.captureException);
const wrapModuleWithSentry = wrapModule(sentryClient.captureException);

const wrapModuleWithWarning = wrapModule((err: AnyFixMe) => {
  // eslint-disable-next-line no-console
  console.warn(err);
});

export const CodeEditor = wrapComponentWithSentry(
  _codeEditorCreator({ typingsLoader }),
) as AnyFixMe;

export const models = () => wrapModuleWithSentry(_modelsApiCreator());

export const createNamespacedModelsApi = (...args: AnyFixMe[]) =>
  wrapModuleWithSentry(_namespacedModelsApiCreator(...args));
export const registerCompletionItemProvider = wrapModuleWithSentry(
  (languageId: string, provider: monaco.languages.CompletionItemProvider) =>
    registerCustomCompletionItemProvider(languageId, provider),
);
export const registerCodeValidator = wrapModuleWithSentry(
  (languageId: string, validator: CustomCodeValidator) =>
    registerCustomCodeValidator(languageId, validator),
);
export const codeExportsService = wrapModuleWithWarning(_codeExportsService);
export const UserError = userError;
export const decorationTypes = _decorationTypes;
export const BI = { setBiContext };
export const definitionsApi = definitionsApiCreator({ typingsLoader });
export { KeyBindings };
export const languageConstants = languageConsts;
export const MarkerSeverity = monaco.MarkerSeverity;

export type MonacoEditorTextModel = monaco.editor.ITextModel;
export type MonacoLanguagesCompletionItemProvider =
  monaco.languages.CompletionItemProvider;
export type MonacoPosition = monaco.IPosition;
export type MonacoRange = monaco.IRange;
export type MonacoLanguagesCompletionItem = monaco.languages.CompletionItem;
export type MonacoModelMarker = monaco.editor.IMarkerData;
export type CustomCodeValidator = (
  modelContent: string,
  path: string,
) => MonacoModelMarker[];
export const monacoTokenize = (text: string, languageId: string) =>
  monaco.editor.tokenize(text, languageId);
export const getCodeLinesCount = () => {
  return monaco.editor.getModels()[0]
    ? monaco.editor.getModels()[0]!.getLineCount()
    : 0;
};
